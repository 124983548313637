/* .c-enterprise
-----------------------------------------------------------*/
$r: ".c-enterprise";

// block
//-----------------------------------------------------------
#{$r} {
  background-color: #e6dcbd;
  background-image: url(#{$PATH_TO_IMAGES}/waves.svg);
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% auto;

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: calc(24% - 294px);
    padding-right: calc(24% - 294px);

    @media (max-width: 1680px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @media (max-width: 1440px - 1) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (max-width: 992px - 1) {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__image {
    flex: 1;
    margin-right: 32px;

    @media (max-width: 992px - 1) {
      display: none;
    }
  }

  &__body {
    width: 50%;
    margin-left: calc(24% - 294px);

    @media (max-width: 1440px - 1) {
      margin-left: 0;
    }

    @media (max-width: 992px - 1) {
      width: auto;
    }
  }

  &__title {
    font-size: 60px;
    font-weight: 600;
    line-height: 1.3;

    @media (max-width: 1680px - 1) {
      font-size: 52px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 48px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 40px;
    }

    @media (max-width: 480px - 1) {
      font-size: 32px;
    }

    small {
      display: block;
      font-size: 32px;
      margin-bottom: -0.2em;

      @media (max-width: 1680px - 1) {
        font-size: 28px;
      }

      @media (max-width: 1440px - 1) {
        font-size: 24px;
      }

      @media (max-width: 1280px - 1) {
        font-size: 20px;
      }

      @media (max-width: 480px - 1) {
        font-size: 18px;
        margin-bottom: -0.1em;
      }
    }
  }

  &__content {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.3;

    @media (max-width: 1680px - 1) {
      font-size: 28px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 24px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 20px;
    }

    @media (max-width: 480px - 1) {
      font-size: 18px;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding-right: 12%;
    margin-top: 24px;

    @media (max-width: 1280px - 1) {
      padding-right: 0;
    }

    &--item {
      width: calc(50% - 32px);
      margin-top: 16px;
      margin-right: 64px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-of-type(-n + 2) {
          margin-top: 0;
      }

      @media (max-width: 480px - 1) {
        &:nth-child(n) {
          margin-right: 0;
          width: auto;
        }

        & + & {
          margin-top: 16px;
        }
      }
    }

    &--header {
      display: flex;
      align-items: center;
    }

    &--icon-wrap {
      margin-right: 0.6em;
    }

    &--icon {
      width: 2.5em;
      height: 2.5em;
    }

    &--title {
      flex: 1;
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;
      font-family: Arial, sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      min-height: 2.4em;

      @media (max-width: 1680px - 1) {
        font-size: 18px;
      }
    }

    &--content {
      font-size: 16px;
      line-height: 1.6;

      @media (max-width: 1680px - 1) {
        font-size: 14px;
      }
    }
  }

  &__controls {
    display: flex;
    margin-top: 32px;

    @media (max-width: 992px - 1) {
      justify-content: center;
    }

    @media (max-width: 480px - 1) {
      flex-direction: column;
    }

    #{$r}__button + #{$r}__button {
      margin-left: 24px;

      @media (max-width: 1280px - 1) {
        margin-left: 16px;
      }

      @media (max-width: 480px - 1) {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }

  &__button {
    padding: 15px 32px;
    font-size: 14px;

    @media (max-width: 1680px - 1) {
      padding: 12px 28px;
    }

    @media (max-width: 1440px - 1) {
      padding: 10px 24px;
    }

    @media (max-width: 1280px - 1) {
      padding: 10px 22px 9px 22px;
      font-size: 12px;
    }
  }


  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
