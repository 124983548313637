/* screen - contact */

.contact {
  background-color: var(--white);
}

.contact .overlap-group-4 {
  height: 51px;
}

.contact .text-accent {
  height: 18px;
  left: 337px;
  position: absolute;
  top: 33px;
  width: 104px;
}

.contact .text-5 {
  letter-spacing: 0;
  line-height: 50.4px;
  white-space: nowrap;
}
.contact-form-container {
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 4px 4px 20px #0000001a;
}
.contact .overlap-group3 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  flex-direction: column;
  margin-top: 52px;
  min-height: 658px;
  padding: 21px 12px;
}

.contact .flex-row {
  align-items: center;
  align-self: flex-end;
  display: flex;
  height: 29px;
  margin-right: 10px;
}

.contact .text-64 {
  letter-spacing: 0;
  line-height: 29.2px;
  min-height: 29px;
  min-width: 336px;
  white-space: nowrap;
}

.contact .minimize-icon {
  background-color: var(--sunglow);
  height: 2px;
  margin-bottom: 3.0px;
  width: 13px;
}

.contact .text-63 {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 26px;
  min-height: 27px;
  white-space: nowrap;
}

.contact .flex-row-1 {
  align-items: flex-start;
  display: flex;
  margin-left: 18px;
  margin-top: 38px;
  min-width: 642px;
}

.contact .overlap-group {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 50px;
  min-width: 307px;
  padding: 13px 20px;
}

.contact .contact-input {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 50px;
  padding: 13px 20px;
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}
.contact-input-area {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 150px;
  padding: 13px 20px;
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}
.form-check-input{
  margin-top: 7px !important;
}
.contact-submit-btn {
  background: #0F2736;
  border-radius: 10px;
  padding: 20px 10px;
}
.contact .overlap-group-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 50px;
  margin-left: 28px;
  min-width: 307px;
  padding: 13px 20px;
}

.contact .last-name {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}

.contact .flex-row-2 {
  align-items: flex-start;
  display: flex;
  margin-left: 18px;
  margin-top: 35px;
  min-width: 642px;
}

.contact .email {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}

.contact .phone-number {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}

.contact .overlap-group5 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 50px;
  margin-left: 18px;
  margin-top: 35px;
  min-width: 642px;
  padding: 13px 20px;
}

.contact .company-name {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}

.contact .overlap-group4 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 121px;
  margin-left: 18px;
  margin-top: 35px;
  min-width: 642px;
  padding: 13px 20px;
}

.contact .message {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  height: 50px;
  white-space: nowrap;
}

.contact .flex-row-3 {
  align-items: flex-start;
  display: flex;
  height: 68px;
  margin-left: 18px;
  margin-top: 25px;
  min-width: 642px;
}

.contact .privacy {
  align-items: flex-end;
  display: flex;
  height: 21px;
  min-width: 228px;
}

.contact .rectangle-30 {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 19px;
  width: 19px;
}

.contact .text-62 {
  letter-spacing: 0;
  line-height: 20.8px;
  margin-left: 14px;
  min-height: 21px;
  min-width: 195px;
  white-space: nowrap;
}

.contact .span1 {
  color: var(--black);
  font-family: var(--font-family-lato-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
}

.contact .overlap-group-5 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: var(--firefly);
  border-radius: 10px;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  margin-left: 294px;
  min-width: 120px;
  padding: 12px 29px;
}

.contact .submit {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  white-space: nowrap;
}

.contact .overlap-group-2 {
  align-items: center;
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 70px;
  justify-content: flex-end;
  margin-top: 40px;
  padding: 20px 16px;
}

.contact .text-6 {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 29.2px;
  min-height: 29px;
  white-space: nowrap;
}

.contact .vector {
  height: 14px;
  margin-left: 478px;
  width: 13px;
}

.contact .flex-col-1 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: -24.0px;
  min-height: 1200px;
}

.contact .flex-row-4 {
  margin-top: -20px;
  align-items: flex-start;
  display: flex;
  height: 480px;
}

.contact-info-bg {
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 41px 56px;
}

.contact .corporate {
  letter-spacing: 0;
  line-height: 29.2px;
  min-height: 29px;
  white-space: nowrap;
}

.contact .flex-row-5 {
  align-items: center;
  display: flex;
  height: 216px;
  margin-top: 19px;
}

.contact .flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.0px;
  min-height: 206px;
  width: 20px;
}

.contact-icon {
  height: 21px;
  width: 15px;
  min-width: 15px;
}

.contact .mobile-icon {
  height: 22px;
  margin-left: 3px;
  margin-top: 86px;
  width: 14px;
}

.contact .mail-icon {
  height: 16px;
  margin-top: 61px;
  width: 20px;
}

.contact .flex-col-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 9px;
  min-height: 216px;
}

.contact .address {
  letter-spacing: 0;
}

.contact .text-65 {
  letter-spacing: 0;
}

.contact .text-66 {
  white-space: nowrap;
}

.contact .bg-accent {
  height: 368px;
  margin-left: 14px;
  width: 278px;
}

.contact .overlap-group9 {
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-top: 20px;
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  min-height: 334px;
  padding: 41px 56px;
  width: 516px;
}

.contact .live-support {
  letter-spacing: 0;
  line-height: 29.2px;
  min-height: 29px;
  white-space: nowrap;
}

.contact .text-67 {
  letter-spacing: 0;
  line-height: 36px;
  margin-top: 19px;
  min-height: 36px;
  white-space: nowrap;
}

.contact .flex-row-6 {
  align-items: flex-start;
  display: flex;
  height: 54px;
  margin-top: 23px;
  min-width: 383px;
}

.contact .mobile {
  height: 22px;
  margin-top: 3px;
  width: 14px;
}

.contact .text-68 {
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 12px;
  min-height: 54px;
  width: 357px;
}

.contact .chat-btn {
  align-self: flex-end;
  height: 50px;
  margin-right: 7px;
  margin-top: 28px;
  width: 183px;
}

.contact .overlap-group10 {
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  min-height: 266px;
  padding: 41px 56px;
  width: 516px;
}

.contact .text-69 {
  letter-spacing: 0;
  line-height: 29.2px;
  min-height: 29px;
  white-space: nowrap;
}

.contact .text-70 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 19px;
  min-height: 54px;
}

.contact .overlap-group-6 {
  align-items: center;
  align-self: flex-end;
  background-color: var(--firefly);
  border-radius: 10px;
  display: flex;
  height: 50px;
  margin-right: 7px;
  margin-top: 19px;
  min-width: 191px;
  padding: 12px 16px;
}

.contact .vector-3 {
  height: 24px;
  width: 25px;
}

.contact .send-feedback {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 25.2px;
  margin-left: 11px;
  min-height: 25px;
  min-width: 123px;
  white-space: nowrap;
}

.contact .faq-section {
  align-items: flex-end;
  display: flex;
  height: 504px;
  margin-right: 1.0px;
  margin-top: 100px;
  min-width: 1363px;
}

.contact .flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 504px;
  width: 645px;
}

.contact .overlap-group8-1 {
  height: 54px;
  position: relative;
  width: 471px;
}

.contact .text-accent-1 {
  height: 18px;
  left: 367px;
  position: absolute;
  top: 36px;
  width: 104px;
}

.contact .overlap-group-7 {
  align-items: center;
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 70px;
  justify-content: flex-end;
  margin-top: 62px;
  min-width: 645px;
  padding: 20px 24px;
}

.contact .text-5-1 {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 29.2px;
  min-height: 29px;
  min-width: 500px;
  white-space: nowrap;
}

.contact .vector-1 {
  height: 14px;
  margin-left: 78px;
  margin-top: 4.0px;
  width: 13px;
}

.contact .overlap-group-3 {
  align-items: center;
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 70px;
  justify-content: flex-end;
  margin-top: 36px;
  min-width: 645px;
  padding: 20px 24px;
}

.contact .text-5-2 {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 29.2px;
  min-height: 29px;
  min-width: 284px;
  white-space: nowrap;
}

.contact .vector-2 {
  height: 14px;
  margin-left: 294px;
  margin-top: 4.0px;
  width: 13px;
}

.contact .flex-col-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 71px;
  min-height: 388px;
  width: 645px;
}

.contact .overlap-group4-1 {
  align-items: center;
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 70px;
  justify-content: flex-end;
  min-width: 645px;
  padding: 20px 24px;
}





.contact .privacy-2 {
  left: 298px;
  min-width: 227px;
  position: absolute;
  top: 520px;
  width: 700px;
}

.contact .text-48 {
  letter-spacing: 0;
  line-height: 20.8px;
  margin-left: 14px;
  min-height: 21px;
  min-width: 194px;
  white-space: nowrap;
}

.contact .text-accent-2 {
  height: 21px;
  left: 495px;
  position: absolute;
  top: 195px;
  width: 122px;
}
.form-check-label {
  display: inline !important;
  margin-left: 10px;
}



.contact .form-field {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 50px;
  left: 299px;
  position: absolute;
  top: 325px;
  width: 393px;
}

.contact .email-1 {
  left: 319px;
  letter-spacing: 0;
  line-height: 23.4px;
  position: absolute;
  top: 338px;
  white-space: nowrap;
}
