/* .c-1report
-----------------------------------------------------------*/
$r: ".c-1report";

// block
//-----------------------------------------------------------
#{$r} {
  background-color: #ffffff;
  background-image: url(#{$PATH_TO_IMAGES}/waves.svg);
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% auto;

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-top: 30px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: calc(24% - 294px);
    padding-right: calc(24% - 294px);

    @media (max-width: 1680px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @media (max-width: 1440px - 1) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (max-width: 992px - 1) {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__image {
    flex: 1;
    margin-right: 32px;

    @media (max-width: 992px - 1) {
      display: none;
    }
  }

  &__image_mobile {
    text-align: center;
    flex: 1;
    @media (min-width: 992px - 1) {
      display: none;
    }
  }
  &__image_size_mobile {
    max-width: 100px;
  }
  &__body {
    width: 60%;
    margin-left: calc(24% - 294px);

    @media (max-width: 1440px - 1) {
      margin-left: 0;
    }

    @media (max-width: 992px - 1) {
      width: auto;
    }
  }

  &__title {
    font-size: 60px;
    font-weight: 600;
    line-height: 1.3;

    @media (max-width: 1680px - 1) {
      font-size: 52px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 48px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 40px;
    }

    @media (max-width: 480px - 1) {
      font-size: 32px;
    }

    small {
      display: block;
      font-size: 26px;
      margin-bottom: -0.2em;
      color: #FFC136;
      text-transform: uppercase;
      @media (max-width: 1680px - 1) {
        font-size: 28px;
      }

      @media (max-width: 1440px - 1) {
        font-size: 24px;
      }

      @media (max-width: 1280px - 1) {
        font-size: 20px;
      }

      @media (max-width: 480px - 1) {
        font-size: 18px;
        margin-bottom: -0.1em;
      }
    }
  }

  &__content {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.3;

    @media (max-width: 1680px - 1) {
      font-size: 28px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 24px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 20px;
    }

    @media (max-width: 480px - 1) {
      font-size: 18px;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding-right: 12%;
    margin-top: 24px;

    @media (max-width: 1280px - 1) {
      padding-right: 0;
    }

    &--item {
      width: calc(50% - 32px);
      margin-top: 16px;
      margin-right: 64px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-of-type(-n + 2) {
          margin-top: 0;
      }

      @media (max-width: 480px - 1) {
        &:nth-child(n) {
          margin-right: 0;
          width: auto;
        }

        & + & {
          margin-top: 16px;
        }
      }
    }

    &--header {
      display: flex;
      align-items: center;
    }

    &--icon-wrap {
      margin-right: 0.6em;
    }

    &--icon {
      width: 2.5em;
      height: 2.5em;
    }

    &--title {
      flex: 1;
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;
      font-family: Arial, sans-serif;
      font-size: 26px;
      font-weight: 600;
      line-height: 1.2;
      min-height: 2.4em;

      @media (max-width: 1680px - 1) {
        font-size: 18px;
      }
    }

    &--content {
      font-size: 16px;
      line-height: 1.6;

      @media (max-width: 1680px - 1) {
        font-size: 14px;
      }
    }
  }

  &__controls {
    display: flex;
    margin-top: 32px;

    @media (max-width: 992px - 1) {
      justify-content: center;
    }

    @media (max-width: 480px - 1) {
      flex-direction: column;
    }

    #{$r}__button + #{$r}__button {
      margin-left: 24px;

      @media (max-width: 1280px - 1) {
        margin-left: 16px;
      }

      @media (max-width: 480px - 1) {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }

  &__button {
    padding: 15px 32px;
    font-size: 14px;

    @media (max-width: 1680px - 1) {
      padding: 12px 28px;
    }

    @media (max-width: 1440px - 1) {
      padding: 10px 24px;
    }

    @media (max-width: 1280px - 1) {
      padding: 10px 22px 9px 22px;
      font-size: 12px;
    }
  }

  &__form {
    flex: 400;
    margin-right: 15%;

    @media (max-width: 1440px - 1) {
      margin-right: 16%;
    }

    @media (max-width: 1280px - 1) {
      margin-left: 4%;
      margin-right: 18%;
    }

    @media (max-width: 992px - 1) {
      margin-left: 0;
      margin-right: 0;
    }

    input[type="submit"] {
      @media (max-width: 768px - 1) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 16px;
      }

      @media (max-width: 480px - 1) {
        width: 100%;
        margin-top: 0;
      }
    }
    &-title {
      margin-top: 40px;
      font-family: Arial, sans-serif;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .hs-input{
    margin-top: 20px;
    background: #FFFFFF;
    font-size: 18px;
    padding: 20px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .form-columns-1{
    padding-right: 0px !important;
    margin-right: 0px !important;
    max-width: none !important;
  }
  .form-columns-2{
    padding-right: 0px !important;
    margin-right: 0px !important;
    max-width: none !important;
  }
  .hs-button{
    color: rgb(255, 255, 255);
    margin-top: 20px;
    border-color: #5a867c;
    background-color: #5a867c;
    font-size: 13px;
    height: 100%;
    letter-spacing: 1px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 600;
    max-width: 95%;
    border-radius: 4px;
    border: 1px solid transparent;
    font-family: Arial, sans-serif;
    transition: border-color 0.3s,
      color 0.3s,
      background-color 0.3s,
      opacity 0.3s;
    text-transform: uppercase;
    text-align: center;
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
