/* .c-unordered-line-list
-----------------------------------------------------------*/
$r: ".c-unordered-line-list";

// block
//-----------------------------------------------------------
#{$r} {

  // element
  //---------------------------------------------------------
  &__item {
    display: inline-block;
    padding-right: 10px;

    &::before {
      content: "\2022"; // Dot bullet list - \2022, Space - \0020
      margin-right: .18em;
    }

    // #{$r} & {
    //   &:first-child {
    //     &::before {
    //       display: none;
    //     }
    //   }
    // }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
