.footer-section {
  padding: 40px;
}
.footer .sep {
  padding-bottom: 60px;
}
.social-footer-icon {
  max-width: none;
  height: 17px;
}
.footer-logo {
  height: 79px;
  max-width: none;
}
.map-pin {
  margin-top: 5px;
  max-width: none;
  display: block;
  margin-left: auto;
}
/*
.footer .sep {
  height: 2px;
  margin-left: -1px;
  margin-top: -1px;
  width: 1642px;
}

.footer .footer-container {
  align-items: flex-start;
  display: flex;
  height: 141px;
  margin-top: 59px;
}

.footer .privacy-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 141px;
  width: 118px;
}

.footer .sz-logo {
  height: 80px;
  margin-left: 0;
  width: 118px;
}

.footer .privacy {
  letter-spacing: 0;
  line-height: 20.8px;
  margin-top: 40px;
  min-height: 21px;
  opacity: 0.7;
  white-space: nowrap;
}

.footer .cookie {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 20.8px;
  margin-left: 20px;
  min-height: 21px;
  min-width: 97px;
  opacity: 0.7;
  white-space: nowrap;
}

.footer .location {
  align-items: flex-start;
  display: flex;
  height: 50px;
  margin-left: 42px;
  margin-top: 0.18px;
  min-width: 235px;
}

.footer .map-pin {
  height: 21px;
  width: 15px;
}

.footer .address {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-left: 10px;
  min-height: 50px;
}

.footer .footer-links {
  align-items: flex-start;
  display: flex;
  height: 70px;
  margin-left: 456px;
  margin-top: 0.18px;
  min-width: 328px;
}

.footer .home-group {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  width: 52px;
}

.footer .home {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  white-space: nowrap;
}

.footer .about {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-top: 20px;
  min-height: 25px;
  white-space: nowrap;
}

.footer .solutions-group {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 57px;
  min-height: 70px;
  width: 77px;
}

.footer .solutions-footer {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  white-space: nowrap;
}

.footer .latest-footer {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-top: 20px;
  min-height: 25px;
  white-space: nowrap;
}

.footer .products-group {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 58px;
  min-height: 70px;
  width: 74px;
}

.footer .products {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  white-space: nowrap;
}

.footer .place {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-top: 20px;
  min-height: 25px;
  white-space: nowrap;
}

.footer .facebook-1 {
  height: 19px;
  margin-left: 87px;
  margin-top: 5.18px;
  width: 10px;
}

.footer .twitter-1 {
  height: 15px;
  margin-left: 25px;
  margin-top: 7.1px;
  width: 18px;
}

.footer .instagram-1 {
  height: 18px;
  margin-left: 25px;
  margin-top: 5.96px;
  width: 18px;
}

.footer .linkedin-1 {
  height: 18px;
  margin-left: 25px;
  margin-top: 5.18px;
  width: 19px;
}*/
