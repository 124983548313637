/* .c-recognition
-----------------------------------------------------------*/
$r: ".c-recognition";

// block
//-----------------------------------------------------------
#{$r} {
  // border-bottom: 8px solid #d1c38e;

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;

    @media (max-width: 1440px - 1) {
      padding-left: 24px;
    }

    @media (max-width: 1280px - 1) {
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 16px;
    }

    @media (max-width: 992px - 1) {
      flex-direction: column-reverse;
      padding-left: 0;
    }

    @media (max-width: 768px - 1) {
      padding-left: 0;
    }

    @media (max-width: 480px - 1) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &__image {
    flex: 8 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 32px;

    @media (max-width: 1280px - 1) {
      margin-left: 24px;
    }

    @media (max-width: 992px - 1) {
      margin-left: 0;
      margin-top: 16px;
      flex: none;
    }

    @media (max-width: 480px - 1) {
      // margin-left: 16px;
    }

    img {
      display: block;

      @media (max-width: 480px - 1) {
        display: none;
      }

      &.only-phone-portrait {
        display: none;

        @media (max-width: 480px - 1) {
          display: block;
        }
      }
    }
  }

  &__body {
    flex: 4 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 32px;
    position: relative;

    @media (max-width: 1280px - 1) {
      margin-right: 24px;
    }

    @media (max-width: 992px - 1) {
      margin-right: 0;
      padding-bottom: 16px;
      flex: none;
    }

    @media (max-width: 480px - 1) {
      // margin-right: 16px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      margin-left: 1px;
      width: 2px;
      background-color: #d1c38e;
      z-index: 0;

      @media (max-width: 992px - 1) {
        top: 100%;
        bottom: auto;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
      }
    }
  }

  &__title {
    font-family: $ff-helvetica-neue;
    font-size: 56px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 16px;
    text-transform: capitalize;

    @media (max-width: 1680px - 1) {
      font-size: 52px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 48px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 40px;
    }

    @media (max-width: 992px - 1) {
      font-size: 32px;
      text-align: center;
      margin-bottom: 8px;
    }

    @media (max-width: 768px - 1) {
      // font-size: 32px;
    }

    @media (max-width: 480px - 1) {
      font-size: 28px;
    }
  }

  &__content {
    @media (max-width: 992px - 1) {
      display: none;
    }

    img {
      width: 80%;
      display: block;
    }
  }


  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
