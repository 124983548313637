/* .c-footer
-----------------------------------------------------------*/
$r: ".c-footer";

// block
//-----------------------------------------------------------
#{$r} {

  // element
  //---------------------------------------------------------

  &__top {

  }

  &__bottom {
    background-color: #b4d0be;
  }

  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
  }

  &__top &__inner {
    padding-top: 32px;
    padding-bottom: 32px;

    @media (max-width: 1280px - 1) {
      padding-top: 24px;
      padding-bottom: 24px;
      flex-wrap: wrap;
    }

    @media (max-width: 480px - 1) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &__contact {
    font-size: 16px;
    width: 168px;

    @media (max-width: 1440px - 1) {
      font-size: 14px;
    }

    @media (max-width: 1280px - 1) {
      order: 2;
    }

    @media (max-width: 768px - 1) {
      font-size: 12px;
      margin-right: auto;
    }

    @media (max-width: 480px - 1) {
      margin-left: auto;
      margin-right: auto;
    }

    img {
      display: block;
      height: 80px;
    }
  }

  &__nav {
    flex: 1;
    display: flex;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: -8px;

    @media (max-width: 1680px - 1) {
      flex-wrap: wrap;
    }

    @media (max-width: 1280px - 1) {
      flex: none;
      order: 1;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 32px;
    }

    @media (max-width: 992px - 1) {
      margin-left: 10%;
      width: calc(100% - 10%);
    }
  }


  &__nav-item {
    flex: 1;
    margin-top: 8px;

    @media (max-width: 1680px - 1) {
      flex: none;
      width: 33%;
    }

    @media (max-width: 1280px - 1) {
      width: auto;
      flex: 1;
    }

    @media (max-width: 992px - 1) {
      flex: none;
      width: 33%;
    }

    @media (max-width: 480px - 1) {
      width: 50%;
    }
  }

  &__nav-item + &__nav-item {
    margin-left: 32px;

    @media (max-width: 1680px - 1) {
      margin-left: 0;
    }
  }

  &__nav-item-header {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: 1.3;

    @media (max-width: 1440px - 1) {
      font-size: 16px;
    }

    @media (max-width: 768px - 1) {
      font-size: 14px;
    }
  }

  &__nav-item-list {
    font-size: 16px;
    line-height: 1.3;

    @media (max-width: 1440px - 1) {
      font-size: 14px;
    }

    @media (max-width: 768px - 1) {
      font-size: 12px;
    }
  }

  &__nav-item-link {
    color: inherit;
    display: block;
    margin-top: 8px;
  }


  &__subscribe {
    width: 360px;

    @media (max-width: 1280px - 1) {
      order: 2;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 768px - 1) {
      margin-right: 0;
      margin-left: 24px;
      max-width: 360px;
      width: auto;
      flex: 1 1;
    }

    @media (max-width: 480px - 1) {
      flex: none;
      order: 3;
      max-width: 100%;
      margin-left: 0;
      margin-top: 16px;
    }
  }

  &__subscribe-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.3;

    @media (max-width: 768px - 1) {
      font-size: 20px;
    }
  }

  &__subscribe-text {
    margin-top: 8px;
    font-size: 16px;

    @media (max-width: 1440px - 1) {
      font-size: 14px;
    }

    @media (max-width: 768px - 1) {
      font-size: 12px;
    }
  }

  &__subscribe-form {
    margin-top: 16px;

    .c-form__inner {
      display: flex;

      @media (max-width: 768px - 1) {
        flex-direction: column;
      }
    }

    .c-form__row {
      margin-top: 0;
      flex: 1;
    }

    .c-form__row + .c-form__row {
      flex: none;
      margin-left: 16px;

      @media (max-width: 768px - 1) {
        flex: 1;
        margin-top: 8px;
        margin-left: 0;

        input {
          width: 100%;
        }
      }
    }
  }

  &__bottom &__inner {
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;

    @media (max-width: 768px - 1) {
      flex-direction: column-reverse;
      justify-content: center;
    }
  }

  &__copywrite {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;

    @media (max-width: 1280px - 1) {
      font-size: 12px;
    }
  }

  &__social {
    display: flex;
    margin-left: auto;
    margin-top: 8px;
    margin-bottom: 8px;

    @media (max-width: 768px - 1) {
      margin-left: 0;
    }
  }

  &__social-link {
    display: block;
    height: 32px;
    width: 32px;
    cursor: pointer;

    @media (max-width: 1440px - 1) {
      height: 28px;
      width: 28px;
    }

    @media (max-width: 1280px - 1) {
      height: 24px;
      width: 24px;
    }

    // @media (max-width: 992px - 1) {
    //   height: 20px;
    //   width: 20px;
    // }

    & + & {
      margin-left: 8px;
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
