/* .c-main-top
-----------------------------------------------------------*/
$r: ".c-main-top";

// block
//-----------------------------------------------------------
#{$r} {
  background-image: linear-gradient(180deg,#bdd8e6,#cfd9d0);
  overflow: hidden;

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row-reverse;
    background-image: url(#{$PATH_TO_IMAGES}/main-top-white-canvas.svg), url(#{$PATH_TO_IMAGES}/mountains.svg);
    background-position: 100% 0, 0% 100%;
    background-repeat: no-repeat, no-repeat;
    min-height: 624px;
    margin-top: 80px;
    position: relative;
    // background-size: auto, auto;

    @media (max-width: 1680px - 1) {
      min-height: 560px;
    }

    @media (max-width: 1440px - 1) {
      min-height: 464px;
    }

    @media (max-width: 1280px - 1) {
      min-height: 392px;
      margin-top: 60px;
    }

    @media (max-width: 992px - 1) {
      flex-direction: column-reverse;
      position: static;
      background: none;
      min-height: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      width: 50vw;
      background-color: $white;

      @media (max-width: 992px - 1) {
        display: none;
      }
    }
  }

  &__images {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 560px;

    @media (max-width: 1680px - 1) {
      width: 496px;
    }

    @media (max-width: 1440px - 1) {
      width: 408px;
    }

    @media (max-width: 1280px - 1) {
      width: 344px;
    }

    @media (max-width: 992px - 1) {
      flex-direction: row;
      position: relative;
      background-color: $white;
      width: auto;
      padding: 24px 0;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50vw;
        background-color: $white;
      }

      &::before {
        right: 100%;
      }

      &::after {
        left: 100%;
      }
    }
  }

  &__product-logo {
    padding: 24px;

    @media (max-width: 1680px - 1) {
      padding: 20px;
    }

    @media (max-width: 1440px - 1) {
      padding: 16px;
    }

    @media (max-width: 992px - 1) {
      padding: 0;
    }

    img {
      display: block;
    }
  }

  &__shelterzoom {
    img {
      $height: 160px;
      height: $height;

      @media (max-width: 1680px - 1) {
        height: $height * 0.85;
      }

      @media (max-width: 1440px - 1) {
        height: $height * 0.75;
      }

      @media (max-width: 1280px - 1) {
        height: $height * 0.65;
      }

      @media (max-width: 992px - 1) {
        height: $height * 0.5;
      }

      @media (max-width: 480px - 1) {
        height: $height * 0.35;
      }
    }
  }

  &__docuwalk {
    img {
      $height: 88px;
      height: $height;

      @media (max-width: 1680px - 1) {
        height: $height * 0.85;
      }

      @media (max-width: 1440px - 1) {
        height: $height * 0.75;
      }

      @media (max-width: 1280px - 1) {
        height: $height * 0.65;
      }

      @media (max-width: 992px - 1) {
        height: $height * 0.5;
      }

      @media (max-width: 480px - 1) {
        height: $height * 0.35;
      }
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12%;
    padding-right: 15%;
    font-family: $ff-helvetica-neue-condensed;
    font-weight: 700;

    @media (max-width: 992px - 1) {
      background-image: url(#{$PATH_TO_IMAGES}/mountains.svg);
      background-position: 0% 100%;
      background-repeat: no-repeat;
      padding-left: 28%;
      padding-right: 0;
      padding-top: 60px;
      padding-bottom: 24px;
    }

    @media (max-width: 768px - 1) {
      padding-left: 0;
      background: none;
    }

    @media (max-width: 480px - 1) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &__title {
    font-size: 64px;
    line-height: 1.2;
    margin: 16px 0;
    text-transform: capitalize;
    margin-top: auto;

    @media (max-width: 1680px - 1) {
      font-size: 56px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 48px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 44px;
    }

    @media (max-width: 992px - 1) {
      font-size: 48px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    @media (max-width: 768px - 1) {
      font-size: 40px;
    }

    @media (max-width: 480px - 1) {
      font-size: 32px;
    }
  }

  &__content {
    font-size: 40px;
    margin: 16px 0;

    @media (max-width: 1680px - 1) {
      font-size: 32px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 24px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 20px;
    }

    @media (max-width: 992px - 1) {
      font-size: 24px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    @media (max-width: 480px - 1) {
      font-size: 16px;
    }
  }

  &__footer {
    font-size: 40px;
    margin-top: auto;
    margin-bottom: 16px;
    text-transform: uppercase;

    @media (max-width: 1680px - 1) {
      font-size: 32px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 24px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 20px;
      margin-bottom: 16px;
    }

    @media (max-width: 992px - 1) {
      font-size: 24px;
      margin-top: 24px;
      margin-bottom: 8px;
    }

    @media (max-width: 480px - 1) {
      font-size: 16px;
      margin-top: 16px;
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
