/* .c-shelterzoom
-----------------------------------------------------------*/
$r: ".c-shelterzoom";

// block
//-----------------------------------------------------------
#{$r} {

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    padding-top: 24px;
    padding-bottom: 24px;

    @media (max-width: 992px - 1) {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 480px - 1) {
      max-width: 375px;
    }
  }

  &__body {

  }

  &__title {
    font-family: $ff-helvetica-neue;
    font-size: 52px;
    font-weight: 700;
    text-align: center;
    line-height: 1.3;

    @media (max-width: 1680px - 1) {
      font-size: 48px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 40px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 32px;
    }
  }

  &__content {
    margin-top: 24px;

    @media (max-width: 1440px - 1) {
      margin-top: 20px;
    }

    @media (max-width: 1280px - 1) {
      margin-top: 16px;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -32px;
    margin-top: -32px;

    @media (max-width: 1440px - 1) {
      margin-left: -24px;
      margin-top: -24px;
    }

    @media (max-width: 480px - 1) {
      margin-left: -16px;
      margin-top: -16px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    margin-top: 32px;
    padding: 32px;
    background-color: #ceded4;
    border-radius: $border-radius;
    flex: 1 0 calc(0.25 * 100% - 32px);

    @media (max-width: 1680px - 1) {
      padding: 24px;
    }

    @media (max-width: 1440px - 1) {
      flex: 1 0 calc(0.25 * 100% - 24px);
      margin-left: 24px;
      margin-top: 24px;
    }

    @media (max-width: 1280px - 1) {
      padding: 16px;
    }

    @media (max-width: 992px - 1) {
      flex: 1 0 calc(0.5 * 100% - 24px);
    }

    @media (max-width: 480px - 1) {
      flex: 1 0 calc(1 * 100% - 16px);
      margin-left: 16px;
      margin-top: 16px;
    }
  }

  &__card-title {
    font-family: $ff-helvetica-neue;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;

    @media (max-width: 1440px - 1) {
      font-size: 18px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 16px;
    }
  }

  &__card-content {
    margin-top: 16px;
    height: 96px;
    font-size: 16px;

    @media (max-width: 1440px - 1) {
      font-size: 14px;
      margin-top: 12px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 12px;
      margin-top: 8px;
    }

    @media (max-width: 480px - 1) {
      height: auto;
    }
  }

  &__card-controls {
    display: flex;
    margin-top: auto;
  }

  &__card-button {
    font-family: $ff-helvetica-neue;
    font-size: 16px;
    font-weight: 700;
    color: $body-color;
    cursor: pointer;

    @media (max-width: 1440px - 1) {
      font-size: 14px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 12px;
    }

    @media (max-width: 480px - 1) {
      margin-top: 8px;
      box-sizing: content-box;
      position: relative;

      &::before {
        content: '';
        top: -8px;
        left: -16px;
        right: -16px;
        bottom: -8px;
        position: absolute;
      }
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
