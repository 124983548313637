/* .c-blockchain-features
-----------------------------------------------------------*/
$r: ".c-blockchain-features";

// block
//-----------------------------------------------------------
#{$r} {
  overflow: hidden;
  background-color: #f1ecdb;

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 32px;

    @media (max-width: 768px - 1) {
      flex-direction: column-reverse;
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
      min-width: 0;
    }
  }

  &__image {
    flex: 1;

    @media (max-width: 992px - 1) {
      width: auto;
    }

    @media (max-width: 768px - 1) {
      margin-top: 24px;
    }
  }

  &__image-inner {
    @media (max-width: 1280px - 1) {
      margin-left: -100vw;
      display: flex;
      justify-content: flex-end;
    }

    @media (max-width: 768px - 1) {
      margin-left: 0;
      display: block;
    }

    img {
      @media (max-width: 1280px - 1) {
        max-height: 480px;
      }

      @media (max-width: 768px - 1) {
        max-height: 100%;
      }
    }
  }

  &__body {
    width: 40%;
    margin-left: 32px;
    margin-right: 32px;

    @media (max-width: 1680px - 1) {
      width: 38%;
    }

    @media (max-width: 1440px - 1) {
      margin-right: 24px;
      margin-left: 24px;
    }

    @media (max-width: 1280px - 1) {
      width: 48%;
    }

    @media (max-width: 992px - 1) {
      margin-right: 0;
    }

    @media (max-width: 768px - 1) {
      width: auto;
      margin-right: 0;
      margin-left: 0;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.3;

    @media (max-width: 1680px - 1) {
      font-size: 34px;
    }

    @media (max-width: 992px - 1) {
      font-size: 28px;
    }

    @media (max-width: 480px - 1) {
      font-size: 32px;
    }
  }

  &__content {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.3;
    margin-top: 24px;

    @media (max-width: 1920px - 1) {
      font-size: 30px;
    }

    @media (max-width: 1680px - 1) {
      font-size: 24px;
      margin-top: 16px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 20px;
    }

    @media (max-width: 768px - 1) {
      font-size: 18px;
    }

    @media (max-width: 480px - 1) {
      font-size: 16px;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding-right: 64px;
    margin-left: -64px;
    margin-top: -32px + 24px;

    @media (max-width: 1680px - 1) {
      padding-right: 0;
      margin-left: -32px;
    }

    @media (max-width: 1440px - 1) {
      margin-top: -16px + 24px;
      margin-left: -16px;
      padding-right: 0;
    }

    &--item {
      flex: 1;
      margin-left: 64px;
      margin-top: 32px;
      flex-basis: 40%;

      @media (max-width: 1680px - 1) {
        margin-left: 32px;
      }

      @media (max-width: 1440px - 1) {
        margin-top: 16px;
        margin-left: 16px;
      }

      @media (max-width: 768px - 1) {
        flex-basis: 100%;
      }
    }

    &--header {
      display: flex;
      align-items: center;
    }

    &--icon-wrap {
      margin-right: 0.6em;
    }

    &--icon {
      width: 2.5em;
      height: 2.5em;
    }

    &--title {
      flex: 1;
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;
      font-family: Arial, sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      min-height: 2.4em;

      @media (max-width: 1920px - 1) {
        font-size: 18px;
      }

      @media (max-width: 1440px - 1) {
        font-size: 16px;
      }
    }

    &--content {
      font-size: 16px;
      line-height: 1.6;
      margin-top: 8px;

      @media (max-width: 1680px - 1) {
        font-size: 14px;
      }

      @media (max-width: 1440px - 1) {
        font-size: 12px;
      }
    }
  }

  &__controls {
    display: flex;
    margin-top: 32px;

    @media (max-width: 992px - 1) {
      justify-content: center;
    }

    @media (max-width: 480px - 1) {
      flex-direction: column;
    }

    #{$r}__button + #{$r}__button {
      margin-left: 24px;

      @media (max-width: 1280px - 1) {
        margin-left: 16px;
      }

      @media (max-width: 480px - 1) {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }

  &__button {
    padding: 15px 32px;
    font-size: 14px;

    @media (max-width: 1680px - 1) {
      padding: 12px 28px;
    }

    @media (max-width: 1440px - 1) {
      padding: 10px 24px;
    }

    @media (max-width: 1280px - 1) {
      padding: 10px 22px 9px 22px;
      font-size: 12px;
    }
  }


  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
