/* screen - ngo-government */

.ngo-government {
  background-color: var(--white);
}

.ngo-government .government-section {
}

.ngo-government-header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 157px;
}

.ngo-government .header-icon {
  height: 120px;
  margin-left: -16px;
  margin-top: -16px;
  width: 120px;
}

.ngo-government .overlap-group {
  position: relative;
}

.ngo-government .text-accent {
  height: 21px;
  left: 224px;
  position: absolute;
  top: 33px;
  width: 122px;
}

.ngo-government .ngo-government-1 {
  letter-spacing: 0;
  line-height: 50.4px;
}

.ngo-government .text-10 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 37.0px;
}

.ngo-government .products-section {
}

.ngo-government .flex-col {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 400px;
}

.ngo-government .flex-row {
  align-items: flex-start;
  display: flex;
  margin-top: 42px;
  min-width: 602px;
}

.ngo-government .overlap-group-1 {
  padding: 20px;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;

}



.ngo-government .x1-r-eport {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 99px;
  white-space: nowrap;
}

.ngo-government .text-12 {
  left: 25px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 135px;
  width: 241px;
}

.ngo-government .more-icon-1 {
  display: flex;
  height: 25px;
  left: 146px;
  position: absolute;
  top: 281px;
  width: 120px;
}

.ngo-government .learn-more {
  letter-spacing: 0;
  line-height: 25.2px;
  text-align: right;
  white-space: nowrap;
}

.ngo-government .more-icon {
  height: 14px;
  margin-left: 9px;
  margin-top: 5px;
  width: 14px;
}

.ngo-government .item {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 4px 4px 20px #00000026;
  display: flex;
  height: 80px;
  left: 25px;
  min-width: 80px;
  padding: 17.7px 11.0px;
  position: absolute;
  top: 0;
}

.ngo-government .x1report-logo {
  height: 44px;
  width: 58px;
}

.ngo-government .overlap-group1 {
  margin-left: 20px;
  position: relative;
}

.ngo-government .frame-77 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 280px;
  left: 0;
  position: absolute;
  top: 47px;
  width: 291px;
}


.ngo-government .text-13 {
  left: 26px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 135px;
  width: 241px;
}

.ngo-government .docu-walk {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 99px;
  white-space: nowrap;
}

.ngo-government .icon {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 4px 4px 20px #00000026;
  display: flex;
  height: 80px;
  left: 25px;
  position: absolute;
  top: 0;
  width: 80px;
}

.ngo-government .docuwalk {
  height: 49px;
  margin-left: 15.1px;
  margin-top: 15.7px;
  width: 51px;
}

.ngo-government .bg-accent {
  height: 276px;
  width: 265px;
}
