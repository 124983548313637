/* screen - esignature */

.esignature {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 1800px;
  width: 1920px;
}

.esignature .overlap-group-1 {
  margin-left: 50px;
  margin-top: 246px;
  position: relative;
  width: 1503px;
}

.esignature .bottom-section {
  align-items: flex-end;
  display: flex;
  height: 574px;
  left: 0;
  position: absolute;
  top: 555px;
}

.esignature .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 574px;
  width: 711px;
}

.esignature .heading {
  align-items: flex-end;
  display: flex;
  min-width: 472px;
}

.esignature .overlap-group8 {
  height: 53px;
  margin-bottom: 0;
  position: relative;
  width: 470px;
}

.esignature .text-accent {
  align-items: flex-start;
  display: flex;
  height: 20px;
  justify-content: center;
  left: 348px;
  position: absolute;
  top: 33px;
}

.esignature .overlap-group2 {
  height: 20px;
  margin-top: -0.12px;
  position: relative;
  width: 122px;
}

.esignature .vector-5-stroke {
  height: 17px;
  left: 8px;
  position: absolute;
  top: 3px;
  width: 114px;
}

.esignature .vector-4-stroke {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 122px;
}

.esignature .text-133 {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.esignature .text-134 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 26px;
  min-height: 189px;
  width: 673px;
}

.esignature .flex-row {
  align-items: flex-start;
  display: flex;
  height: 257px;
  margin-top: 49px;
  min-width: 711px;
}

.esignature .flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 257px;
  width: 343px;
}

.esignature .bulet-item {
  align-items: flex-start;
  display: flex;
  min-width: 343px;
}

.esignature .overlap-group {
  align-items: center;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 110px;
  justify-content: flex-end;
  min-width: 341px;
  padding: 0 21px;
}

.esignature .text-1 {
  letter-spacing: 0;
  line-height: 27px;
  min-height: 54px;
  width: 295px;
}

.esignature .bulet-item-1 {
  align-items: flex-start;
  display: flex;
  margin-top: 37px;
  min-width: 343px;
}

.esignature .flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  min-height: 257px;
  width: 343px;
}

.esignature .overlap-group1 {
  height: 313px;
  margin-left: 28px;
  position: relative;
  width: 422px;
}

.esignature .group-21071 {
  height: 290px;
  left: 282px;
  position: absolute;
  top: 0;
  width: 140px;
}

.esignature .bulet-item-2 {
  align-items: flex-start;
  display: flex;
  height: 110px;
  left: 0;
  position: absolute;
  top: 56px;
}

.esignature .bulet-item-3 {
  align-items: flex-start;
  display: flex;
  height: 110px;
  left: 0;
  position: absolute;
  top: 203px;
}

.esignature .top-section {
  align-items: flex-start;
  display: flex;
  height: 571px;
  left: 0;
  position: absolute;
  top: 0;
}

.esignature .left {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  min-height: 372px;
  width: 478px;
}

.esignature .code-icon {
  height: 120px;
  margin-left: -16px;
  margin-top: -16px;
  width: 120px;
}

.esignature .heading-1 {
  align-items: flex-end;
  display: flex;
  min-width: 444px;
}

.esignature .overlap-group1-1 {
  height: 103px;
  margin-bottom: 0;
  position: relative;
  width: 444px;
}

.esignature .text-accent-1 {
  align-items: flex-start;
  display: flex;
  height: 20px;
  justify-content: center;
  left: 65px;
  position: absolute;
  top: 83px;
}

.esignature .text-135 {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
}

.esignature .text-136 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 31px;
  min-height: 54px;
  width: 478px;
}

.esignature .get-started-btn {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  margin-top: 30px;
  min-width: 122px;
}

.esignature .overlap-group3 {
  align-items: flex-end;
  background-color: var(--firefly);
  border-radius: 10px;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  min-width: 120px;
  padding: 12px;
}

.esignature .get-started {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  min-width: 95px;
  white-space: nowrap;
}

.esignature .right {
  align-items: flex-start;
  display: flex;
  margin-left: 218px;
  min-width: 807px;
}

.esignature .overlap-group1-2 {
  height: 571px;
  position: relative;
  width: 807px;
}

.esignature .vector-10 {
  height: 392px;
  left: 407px;
  position: absolute;
  top: 179px;
  width: 400px;
}

.esignature .image-114 {
  height: 430px;
  left: 0;
  position: absolute;
  top: 0;
  width: 560px;
}
