/* .c-products
-----------------------------------------------------------*/
$r: ".c-products";

// block
//-----------------------------------------------------------
#{$r} {

  background-color: #e6dcbd;

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
    align-items: center;

    @media (max-width: 1680px - 1) {
      padding-top: 44px;
      padding-bottom: 44px;
    }

    @media (max-width: 1440px - 1) {
      padding-top: 28px;
      padding-bottom: 28px;
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 1280px - 1) {
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 0;
      padding-right: 0;
    }

    @media (max-width: 992px - 1) {
      flex-direction: column-reverse;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 50 0;
    margin-left: 80px;

    @media (max-width: 1440px - 1) {
      margin-left: 40px;
    }

    @media (max-width: 1280px - 1) {
      margin-left: 24px;
    }

    @media (max-width: 992px - 1) {
      margin-left: 0;
      width: auto;
    }

    img {
      width: 100%;
    }
  }

  &__body {
    flex: 50 0px;
  }

  &__title {
    font-family: $ff-helvetica-neue-condensed;
    font-size: 88px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 0.4em;
    text-transform: capitalize;

    @media (max-width: 1920px - 1) {
      font-size: 80px;
    }

    @media (max-width: 1680px - 1) {
      font-size: 68px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 56px;
    }

    @media (max-width: 992px - 1) {
      font-size: 44px;
    }
  }

  &__content {
    font-size: 24px;

    @media (max-width: 1920px - 1) {
      font-size: 22px;
    }

    @media (max-width: 1680px - 1) {
      font-size: 20px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 18px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 16px;
    }
  }

  &__controls {
    margin-top: 40px;

    @media (max-width: 992px - 1) {
      text-align: center;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
