/* .c-aside-menu
-----------------------------------------------------------*/
$r: ".c-aside-menu";

// block
//-----------------------------------------------------------
#{$r} {

  font-weight: 500;
  position: sticky;
  top: 120px;

  // element
  //---------------------------------------------------------

  &__inner {

  }

  &__item {
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 8px;

    & > #{$r}__link {
      font-weight: 600;
    }
  }

  &__submenu {
    padding-left: 16px;
    margin-top: 8px;
  }

  &__subitem {
    font-size: 14px;
  }

  &__link {
    color: $black;
    display: block;
    cursor: pointer;
    padding: 0.2em 0;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.is-active {
      color: #0e6c75;
      text-decoration: none;
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
