/* screen - about-us */

.about-us {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 100px 0;
  width: 1920px;
}

.about-us .story-section {
  align-items: flex-start;
  display: flex;
  height: 658px;
  margin-left: 145.0px;
  min-width: 1509px;
}

.about-us .left-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 658px;
  width: 535px;
}

.about-us .story-header {
  align-items: flex-end;
  display: flex;
  height: 50px;
  min-width: 169px;
}

.about-us .overlap-group-2 {
  height: 51px;
  margin-bottom: -0.45px;
  position: relative;
  width: 167px;
}

.about-us .text-accent {
  height: 18px;
  left: 63px;
  position: absolute;
  top: 33px;
  width: 104px;
}

.about-us .our-story {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.about-us .quote {
  align-items: center;
  align-self: flex-end;
  display: flex;
  height: 54px;
  margin-top: 29px;
  min-width: 522px;
}

.about-us .quote-accent {
  background-color: var(--sunglow);
  height: 3px;
  margin-left: -23px;
  margin-top: 1.0px;
  transform: rotate(90.00deg);
  width: 49px;
}

.about-us .text-91 {
  letter-spacing: 0;
  line-height: 27px;
  min-height: 54px;
  width: 496px;
}

.about-us .text-92 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 39px;
  min-height: 486px;
  width: 535px;
}

.about-us .story-image {
  height: 598px;
  margin-left: 119px;
  width: 855px;
}

.about-us .clients-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: 190.0px;
  margin-top: 102px;
  min-height: 201px;
  width: 1174px;
}

.about-us .clients-header {
  align-items: flex-start;
  display: flex;
  min-width: 198px;
}

.about-us .overlap-group-3 {
  height: 51px;
  position: relative;
  width: 196px;
}

.about-us .text-accent-1 {
  align-items: flex-start;
  display: flex;
  height: 17px;
  left: 92px;
  position: absolute;
  top: 34px;
}

.about-us .overlap-group1 {
  height: 18px;
  margin-top: -0.16px;
  position: relative;
  width: 104px;
}

.about-us .vector-5-stroke {
  height: 14px;
  left: 6px;
  position: absolute;
  top: 3px;
  width: 97px;
}

.about-us .vector-4-stroke {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 104px;
}

.about-us .our-clients {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.about-us .logo-section {
  align-items: center;
  display: flex;
  margin-top: 58px;
  min-width: 1174px;
}

.about-us .berkshire {
  height: 92px;
  width: 132px;
}

.about-us .coldwell {
  height: 40px;
  margin-bottom: 0.68px;
  margin-left: 75px;
  width: 134px;
}

.about-us .century {
  height: 18px;
  margin-left: 74px;
  width: 179px;
}

.about-us .remax {
  height: 25px;
  margin-left: 75px;
  margin-top: 0.47px;
  width: 133px;
}

.about-us .kw {
  height: 45px;
  margin-bottom: 1.0px;
  margin-left: 75px;
  width: 69px;
}

.about-us .ris-media {
  height: 56px;
  margin-left: 74px;
  width: 154px;
}

.about-us .impact-section {
  align-items: center;
  align-self: flex-start;
  display: flex;
  height: 951px;
  justify-content: flex-end;
  margin-left: -351px;
  margin-top: 71px;
  min-width: 2131px;
}

.about-us .impact-hero {
  height: 596px;
  margin-bottom: 169.0px;
  width: 1007px;
}

.about-us .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 186px;
  min-height: 951px;
  width: 783px;
}

.about-us .impact-header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 193px;
  padding: 0 0.0px;
  width: 767px;
}

.about-us .overlap-group-4 {
  height: 109px;
  position: relative;
  width: 288px;
}

.about-us .text-accent-2 {
  height: 18px;
  left: 184px;
  position: absolute;
  top: 68px;
  width: 104px;
}

.about-us .header-accent {
  height: 109px;
  left: 0;
  position: absolute;
  top: 0;
  width: 110px;
}

.about-us .our-impact {
  left: 91px;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 33px;
  white-space: nowrap;
}

.about-us .text-85 {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 27px;
  margin-right: 2.0px;
  margin-top: 3px;
  min-height: 81px;
  width: 674px;
}

.about-us .copy-section {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  height: 725px;
  margin-top: 33px;
  min-width: 724px;
}

.about-us .flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 654px;
  width: 19px;
}

.about-us .number {
  color: var(--sunglow);
  font-family: var(--font-family-lato);
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 42px;
  min-height: 42px;
  white-space: nowrap;
}

.about-us .number-1 {
  color: #afd4fe;
  font-family: var(--font-family-lato);
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 42px;
  margin-top: 111px;
  min-height: 42px;
  white-space: nowrap;
}

.about-us .number-2 {
  color: #89cfc4;
  font-family: var(--font-family-lato);
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 42px;
  margin-top: 111px;
  min-height: 42px;
  white-space: nowrap;
}

.about-us .number-3 {
  color: #b1c1ff;
  font-family: var(--font-family-lato);
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 42px;
  margin-top: 111px;
  min-height: 42px;
  white-space: nowrap;
}

.about-us .number-4 {
  color: #ffa2a2;
  font-family: var(--font-family-lato);
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 42px;
  margin-top: 111px;
  min-height: 42px;
  white-space: nowrap;
}

.about-us .flex-col-2 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: 13px;
  min-height: 720px;
  width: 674px;
}

.about-us .text-86 {
  letter-spacing: 0;
  line-height: 27px;
  min-height: 108px;
  width: 674px;
}

.about-us .text {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 45px;
  min-height: 108px;
  width: 674px;
}

.about-us .values-section {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  height: 677px;
  margin-right: -210.73px;
  margin-top: 93px;
  min-width: 1853px;
}

.about-us .left-section-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 658px;
  width: 717px;
}

.about-us .values-header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 214px;
  width: 719px;
}

.about-us .header {
  align-items: flex-end;
  display: flex;
  height: 52px;
  min-width: 198px;
}

.about-us .overlap-group-5 {
  height: 53px;
  margin-bottom: -0.45px;
  position: relative;
  width: 196px;
}

.about-us .text-accent-3 {
  height: 18px;
  left: 92px;
  position: absolute;
  top: 35px;
  width: 104px;
}

.about-us .our-values {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.about-us .text-84 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 27px;
  min-height: 135px;
  width: 719px;
}

.about-us .flex-row {
  align-items: flex-start;
  display: flex;
  height: 406px;
  margin-top: 38px;
  min-width: 719px;
}

.about-us .flex-col-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 406px;
  width: 343px;
}

.about-us .item {
  align-items: flex-start;
  display: flex;
  min-width: 343px;
}

.about-us .overlap-group {
  align-items: center;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 110px;
  justify-content: flex-end;
  min-width: 341px;
  padding: 0 21px;
}

.about-us .text-1 {
  letter-spacing: 0;
  line-height: 27px;
  min-height: 54px;
  width: 295px;
}

.about-us .item-1 {
  align-items: flex-start;
  display: flex;
  margin-top: 38px;
  min-width: 343px;
}

.about-us .flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 33px;
  min-height: 406px;
  width: 343px;
}

.about-us .values-hero {
  align-self: flex-end;
  height: 592px;
  margin-left: 231px;
  width: 800px;
}

.about-us .investor-section {
  align-items: flex-start;
  display: flex;
  height: 554px;
  margin-right: 203.0px;
  margin-top: 101px;
  min-width: 1467px;
}

.about-us .left-section-2 {
  align-items: flex-start;
  display: flex;
  min-width: 690px;
  padding: 0 0.0px;
}

.about-us .overlap-group-6 {
  height: 389px;
  position: relative;
  width: 688px;
}

.about-us .header-accent-1 {
  height: 18px;
  left: 352px;
  position: absolute;
  top: 40px;
  width: 104px;
}

.about-us .text-75 {
  left: 153px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 85px;
  width: 535px;
}

.about-us .investor-relations {
  left: 153px;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
}

.about-us .bg-accent {
  height: 389px;
  left: 0;
  position: absolute;
  top: 0;
  width: 188px;
}

.about-us .form-section {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: 135px;
  min-height: 469px;
  width: 642px;
}

.about-us .flex-row-1 {
  align-items: flex-start;
  display: flex;
  height: 135px;
  min-width: 644px;
}

.about-us .flex-col-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 135px;
  width: 309px;
}

.about-us .name {
  align-items: flex-start;
  display: flex;
  min-width: 309px;
}

.about-us .overlap-group-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 50px;
  min-width: 307px;
  padding: 13px 20px;
}

.about-us .first-name {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}

.about-us .email {
  align-items: flex-start;
  display: flex;
  margin-top: 35px;
  min-width: 309px;
}

.about-us .email-1 {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}

.about-us .flex-col-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 26px;
  min-height: 135px;
  width: 309px;
}

.about-us .last-name {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}

.about-us .phone {
  align-items: flex-start;
  display: flex;
  margin-top: 35px;
  min-width: 309px;
}

.about-us .phone-number {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}

.about-us .form-section-item {
  align-items: flex-start;
  display: flex;
  margin-top: 35px;
  min-width: 644px;
}

.about-us .overlap-group4 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 50px;
  min-width: 642px;
  padding: 13px 20px;
}

.about-us .company-name {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}

.about-us .overlap-group5 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 121px;
  min-width: 642px;
  padding: 13px 20px;
}

.about-us .message {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  height: 100px;
  white-space: nowrap;
}

.about-us .flex-row-2 {
  align-items: flex-start;
  display: flex;
  height: 68px;
  margin-top: 25px;
  min-width: 644px;
}

.about-us .privacy {
  align-items: flex-end;
  display: flex;
  height: 21px;
  min-width: 227px;
}

.about-us .rectangle-30 {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 19px;
  width: 19px;
}

.about-us .text-76 {
  letter-spacing: 0;
  line-height: 20.8px;
  margin-left: 14px;
  min-height: 21px;
  min-width: 194px;
  white-space: nowrap;
}

.about-us .submit-btn {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  margin-left: 295px;
  min-width: 122px;
}

.about-us .overlap-group6 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  min-width: 120px;
  padding: 12px 29px;
}

.about-us .submit {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  white-space: nowrap;
}

.about-us .careers-section {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  height: 588px;
  justify-content: flex-end;
  margin-left: 5px;
  margin-top: 148px;
  min-width: 1478px;
}

.about-us .left-section-3 {
  height: 493px;
  margin-top: 6px;
  width: 611px;
}

.about-us .right-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 334px;
  min-height: 588px;
  width: 535px;
}

.about-us .careers-header {
  align-items: flex-end;
  display: flex;
  height: 51px;
  min-width: 305px;
}

.about-us .overlap-group-7 {
  height: 52px;
  margin-bottom: -0.45px;
  position: relative;
  width: 303px;
}

.about-us .group-21128 {
  height: 18px;
  left: 199px;
  position: absolute;
  top: 34px;
  width: 104px;
}

.about-us .careers-culture {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.about-us .text-77 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 28px;
  min-height: 405px;
  width: 535px;
}

.about-us .get-in-touch-btn {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  margin-top: 54px;
  min-width: 167px;
}

.about-us .overlap-group1-1 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  min-width: 165px;
  padding: 12px 30px;
}

.about-us .get-in-touch {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  white-space: nowrap;
}
