/* screen - solutions */

.solutions {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  width: 1920px;
}

.solutions .overlap-group-1 {
  height: 633px;
  margin-left: 212px;
  margin-top: 100px;
  position: relative;
  width: 1576px;
}

.solutions .privacy-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 1576px;
}

.solutions .heading {
  align-items: center;
  display: flex;
  height: 231px;
  min-width: 1576px;
}

.solutions .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 28.03px;
  min-height: 159px;
  width: 314px;
}

.solutions .icon {
  align-items: flex-end;
  background-color: #afd4fe;
  border-radius: 10px;
  box-shadow: 4px 4px 20px #00000026;
  display: flex;
  height: 80px;
  justify-content: flex-end;
  min-width: 80px;
  padding: 14.5px 19.8px;
}

.solutions .lock-icon {
  height: 51px;
  width: 39px;
}

.solutions .overlap-group1 {
  height: 55px;
  margin-top: 24px;
  position: relative;
  width: 314px;
}

.solutions .text-acent {
  align-items: flex-start;
  display: flex;
  height: 20px;
  justify-content: center;
  left: 192px;
  position: absolute;
  top: 35px;
}

.solutions .overlap-group2 {
  height: 20px;
  margin-top: -0.12px;
  position: relative;
  width: 122px;
}

.solutions .vector-5-stroke {
  height: 17px;
  left: 8px;
  position: absolute;
  top: 3px;
  width: 114px;
}

.solutions .vector-4-stroke {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 122px;
}

.solutions .privacy-security {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.solutions .bg-accent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 1148px;
  min-height: 231px;
  opacity: 0.2;
  width: 112px;
}

.solutions .ellipse-1 {
  background-color: var(--sunglow);
  border-radius: 55.15px;
  height: 110px;
  margin-left: 0;
  width: 110px;
}

.solutions .vector-1 {
  height: 110px;
  margin-left: -0.22px;
  margin-top: 10px;
  width: 112px;
}

.solutions .text-118 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 13px;
  min-height: 189px;
  width: 486px;
}

.solutions .list-item {
  align-items: center;
  display: flex;
  height: 27px;
  margin-left: 18px;
  margin-top: 32px;
  min-width: 411px;
}

.solutions .chart-icon {
  height: 22px;
  margin-bottom: 1.9px;
  width: 23px;
}

.solutions .text-119 {
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 13px;
  min-height: 27px;
  min-width: 375px;
  white-space: nowrap;
}

.solutions .list-item-1 {
  align-items: center;
  display: flex;
  height: 27px;
  margin-left: 17px;
  margin-top: 20px;
  min-width: 470px;
}

.solutions .blockchain-icon {
  height: 22px;
  margin-top: 0.1px;
  width: 25px;
}

.solutions .text-120 {
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 13px;
  min-height: 27px;
  white-space: nowrap;
  width: 432px;
}

.solutions .list-item-2 {
  align-items: center;
  display: flex;
  height: 27px;
  margin-left: 18px;
  margin-top: 20px;
  min-width: 411px;
}

.solutions .vr-icon {
  height: 15px;
  margin-bottom: 2.38px;
  width: 24px;
}

.solutions .text-121 {
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 12px;
  min-height: 27px;
  min-width: 375px;
  white-space: nowrap;
}

.solutions .list-item-3 {
  align-items: center;
  display: flex;
  height: 27px;
  margin-left: 18px;
  margin-top: 20px;
  min-width: 468px;
}

.solutions .code-icon {
  height: 19px;
  margin-bottom: 2.24px;
  width: 25px;
}

.solutions .text-122 {
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 11px;
  min-height: 27px;
  white-space: nowrap;
  width: 432px;
}

.solutions .product-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 589px;
  position: absolute;
  top: 244px;
  width: 915px;
}

.solutions .text-126 {
  letter-spacing: 0;
  line-height: 28.1px;
  min-height: 56px;
  width: 674px;
}

.solutions .flex-row {
  align-items: flex-start;
  display: flex;
  margin-top: 42px;
  min-width: 917px;
}

.solutions .item {
  align-items: flex-start;
  display: flex;
  min-width: 295px;
}

.solutions .overlap-group {
  height: 277px;
  position: relative;
  width: 291px;
}

.solutions .rectangle-3 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 230px;
  left: 0;
  position: absolute;
  top: 47px;
  width: 291px;
}

.solutions .group-21106 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 4px 4px 20px #00000026;
  display: flex;
  height: 80px;
  left: 25px;
  min-width: 80px;
  padding: 17.7px 11.0px;
  position: absolute;
  top: 0;
}

.solutions .x1report {
  height: 44px;
  width: 58px;
}

.solutions .x1-r-eport {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 99px;
  white-space: nowrap;
}

.solutions .text-123 {
  left: 25px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 135px;
  width: 241px;
}

.solutions .group-21103 {
  align-items: center;
  display: flex;
  height: 25px;
  left: 146px;
  position: absolute;
  top: 231px;
}

.solutions .learn-more {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  min-width: 95px;
  text-align: right;
  white-space: nowrap;
}

.solutions .vector {
  height: 14px;
  margin-bottom: 1.0px;
  margin-left: 9px;
  width: 14px;
}

.solutions .item-1 {
  align-items: flex-start;
  display: flex;
  margin-left: 16px;
  min-width: 295px;
}

.solutions .text-124 {
  left: 26px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 135px;
  width: 241px;
}

.solutions .group-21104 {
  align-items: center;
  display: flex;
  height: 25px;
  left: 147px;
  position: absolute;
  top: 231px;
}

.solutions .docu-walk {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 99px;
  white-space: nowrap;
}

.solutions .group-21101 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 4px 4px 20px #00000026;
  display: flex;
  height: 80px;
  justify-content: flex-end;
  left: 25px;
  min-width: 80px;
  padding: 15.3px 13.9px;
  position: absolute;
  top: 0;
}

.solutions .docuwalk {
  height: 49px;
  width: 51px;
}

.solutions .item-2 {
  align-items: flex-start;
  display: flex;
  height: 277px;
  margin-left: 16px;
  min-width: 295px;
}

.solutions .overlap-group3 {
  height: 293px;
  margin-top: -16px;
  position: relative;
  width: 291px;
}

.solutions .rectangle-39 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 230px;
  left: 0;
  position: absolute;
  top: 63px;
  width: 291px;
}

.solutions .text-125 {
  left: 25px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 151px;
  width: 241px;
}

.solutions .group-21105 {
  align-items: center;
  display: flex;
  height: 25px;
  left: 146px;
  position: absolute;
  top: 247px;
}

.solutions .document-gps {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 115px;
  white-space: nowrap;
}

.solutions .document-gps-1 {
  height: 120px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 120px;
}
