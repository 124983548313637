/* .c-trust
-----------------------------------------------------------*/
$r: ".c-trust";

// block
//-----------------------------------------------------------
#{$r} {
  background-color: $body-color;

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    padding-top: 24px;
    padding-bottom: 24px;
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 992px - 1) {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    // @media (max-width: 480px - 1) {
    //   max-width: 375px;
    // }
  }

  &__title {
    font-family: $ff-helvetica-neue-condensed;
    font-size: 48px;
    font-weight: 900;
    line-height: 1.3;
    color: $white;
    text-align: center;

    @media (max-width: 1440px - 1) {
      font-size: 44px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 40px;
    }

    @media (max-width: 992px - 1) {
      font-size: 36px;
      font-weight: 400;
    }

    @media (max-width: 768px - 1) {
      font-size: 32px;
    }

    @media (max-width: 480px - 1) {
      font-size: 28px;
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
