/* screen - billing */
.pricing-container {
  border: 2px solid #87939A;
  box-sizing: border-box;
  width: 175px;
  border-radius: 20px;
  padding: 20px;
}
.pricing-container-header {
  height: 72px;
}
.pricing-buttons {
  margin-bottom: 90px;
}
.pricing-list{
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.pricing-icon {
  margin-top: -50px;
  margin-left: 60px;
}
.pricing-cost {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 150%;
}
.pricing-btn-dark {
  text-align: center;
  display: block !important;
  background-color: #0F2736;
  border-radius: 10px;
  padding: 10px;
  color: white;
}
.pricing-btn-outline {
  text-align: center;
  display: block !important;
  border: 2px solid #000000;
  color: #000000;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 10px;
}
.no-border {
  height: 62px;
  border-top: none !important;
  border-bottom: none !important;
}
.contract-management {
  height: 50px !important;
}
.tokenization {
  height: 50px !important;
}
.virtual-negation {
  height: 50px !important;
}
.blockchain-signature {
  height: 50px !important;
}
.blockchain-signature {
  height: 55px !important;
}
.permission-management {
  height: 50px !important;
}
.project-management {
  height: 50px !important;
}
.template-management {
  height: 50px !important;
}
.team-management {
  height: 55px !important;
}
.analytics {
  height: 55px !important;
}
.support-community {
  height: 50px !important;
}
.pricing-btn-dark:hover {
    color: white;
    opacity: .8;
}
.pricing-btn-outline:hover {
  color: #000;
  opacity: .8;
}
.pricing-list li{
  line-height: 30px;
  height: 60px;
}
.pricing-col {
  height: 60px;
  line-height: 60px;
  border-top: 1px solid #D9D9D9;
}
.coming-soon{
    height: 60px;
    line-height: 60px;
    border-top: 1px solid #D9D9D9;
}
.billing {
  background-color: var(--white);
}

.billing .overlap-group4 {
  height: 1721px;
  margin-bottom: 0;
  position: relative;
  width: 318px;
}


.billing .fa-qs {
  left: 40px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 764px;
  white-space: nowrap;
}

.billing .industries {
  left: 39px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 511px;
  white-space: nowrap;
}

.billing .solutions {
  left: 39px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 286px;
  white-space: nowrap;
}

.billing .pricing {
  left: 39px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 221px;
  white-space: nowrap;
}

.billing .overview {
  left: 39px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 148px;
  white-space: nowrap;
}



.billing .home {
  height: 18px;
  width: 20px;
}

.billing .real-estate {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 11px;
  min-height: 22px;
  min-width: 80px;
  white-space: nowrap;
}

.billing .icon {
  height: 15px;
  margin-top: 3.0px;
  width: 18px;
}

.billing .surname {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 7px;
  min-height: 22px;
  min-width: 105px;
  white-space: nowrap;
}

.billing .nav-icon {
  height: 18px;
  margin-bottom: 0;
  width: 18px;
}

.billing .place-1 {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 9px;
  min-height: 22px;
  min-width: 39px;
  white-space: nowrap;
}

.billing .icon-1 {
  height: 18px;
  margin-top: 0;
  width: 18px;
}

.billing .ngo-government {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 11px;
  min-height: 22px;
  min-width: 147px;
  white-space: nowrap;
}


.billing .nav-icon-code {
  height: 16px;
  margin-top: 4px;
  width: 22px;
}

.billing .text-12 {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 9px;
  min-height: 44px;
  width: 210px;
}



.billing .nav-link-blockchain {
  height: 18px;
  margin-top: 2.0px;
  width: 22px;
}

.billing .text-13 {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 11px;
  min-height: 66px;
  width: 209px;
}

.billing .arrow-up {
  height: 8px;
  left: 124px;
  position: absolute;
  top: 296px;
  width: 12px;
}

.billing .vector-2 {
  height: 8px;
  left: 129px;
  position: absolute;
  top: 521px;
  width: 12px;
}

.billing .docuwalk {
  height: 44px;
  left: 41px;
  position: absolute;
  top: 46px;
  width: 177px;
}

.billing .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 150px;
}

.billing .title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 99px;
  width: 182px;
}
.billing .billing-frequency {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-l);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 31.5px;
  min-height: 32px;
}

.billing .group-21132 {
  align-items: flex-start;
  display: flex;
  height: 42px;
  margin-top: 25px;
}

.billing .flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 42px;
  width: 110px;
}

.billing .annual {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  white-space: nowrap;
}

.billing .rectangle-45 {
  background-color: var(--sunglow);
  height: 3px;
  margin-top: 14px;
  width: 57px;
}
.billing .rectangle-46 {
  background-color: var(--sunglow);
  height: 3px;
  margin-top: 14px;
  width: 70px;
}

.billing .monthly {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-left: 53px;
  min-height: 25px;
  white-space: nowrap;
}

.billing .overlap-group5 {
  height: 1247px;
  margin-top: 30px;
  position: relative;
  width: 1266px;
}

.billing .features {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 934px;
  position: absolute;
  top: 292px;
  width: 1266px;
}

.billing .text-1 {
  letter-spacing: 0;
  line-height: 27px;
  min-height: 27px;
  white-space: nowrap;
}

.billing .vector {
  height: 2px;
  margin-left: -1px;
  margin-top: 16px;
  width: 1236px;
}

.billing .features-item {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 16px;
  min-height: 27px;
  white-space: nowrap;
}

.billing .vector-1 {
  height: 2px;
  margin-left: -1px;
  margin-top: 14px;
  width: 1236px;
}

.billing .text {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 40px;
  min-height: 27px;
  white-space: nowrap;
}

.billing .overlap-group {
  height: 1263px;
  left: 350px;
  position: absolute;
  width: 215px;
}

.billing .place {
  left: 21px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 86px;
  white-space: nowrap;
}

.billing .rectangle {
  height: 1217px;
  left: 0;
  position: absolute;
  top: 46px;
  width: 215px;
}

.billing .available {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 695px;
  width: 24px;
}

.billing .available-1 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 795px;
  width: 24px;
}

.billing .available-2 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 854px;
  width: 24px;
}

.billing .available-3 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 913px;
  width: 24px;
}

.billing .available-4 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 972px;
  width: 24px;
}

.billing .available-5 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 1158px;
  width: 24px;
}

.billing .not-available {
  height: 17px;
  left: 97px;
  position: absolute;
  top: 433px;
  width: 17px;
}

.billing .not-available-1 {
  height: 17px;
  left: 97px;
  position: absolute;
  top: 491px;
  width: 17px;
}

.billing .not-available-2 {
  height: 17px;
  left: 97px;
  position: absolute;
  top: 553px;
  width: 17px;
}

.billing .not-available-3 {
  height: 17px;
  left: 97px;
  position: absolute;
  top: 610px;
  width: 17px;
}

.billing .not-available-4 {
  height: 17px;
  left: 97px;
  position: absolute;
  top: 1101px;
  width: 17px;
}

.billing .not-available-5 {
  height: 17px;
  left: 97px;
  position: absolute;
  top: 1220px;
  width: 17px;
}

.billing .not-available-6 {
  height: 17px;
  left: 97px;
  position: absolute;
  top: 375px;
  width: 17px;
}

.billing .x20mouser {
  left: 21px;
  letter-spacing: 0;
  line-height: 31.5px;
  position: absolute;
  top: 126px;
  white-space: nowrap;
}

.billing .x-icon {
  height: 100px;
  left: 114px;
  position: absolute;
  top: 0;
  width: 100px;
}

.billing .group-21176 {
  height: 115px;
  left: 21px;
  position: absolute;
  top: 178px;
  width: 169px;
}

.billing .overlap-group1 {
  height: 1263px;
  left: 573px;
  position: absolute;
  top: -16px;
  width: 217px;
}

.billing .text-11 {
  left: 20px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 86px;
  white-space: nowrap;
}

.billing .available-6 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 371px;
  width: 24px;
}

.billing .available-7 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 431px;
  width: 24px;
}

.billing .available-8 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 697px;
  width: 24px;
}

.billing .available-9 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 797px;
  width: 24px;
}

.billing .available-10 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 856px;
  width: 24px;
}

.billing .available-11 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 915px;
  width: 24px;
}

.billing .available-12 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 1160px;
  width: 24px;
}

.billing .available-13 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 974px;
  width: 24px;
}

.billing .not-available-7 {
  height: 17px;
  left: 99px;
  position: absolute;
  top: 493px;
  width: 17px;
}

.billing .not-available-8 {
  height: 17px;
  left: 99px;
  position: absolute;
  top: 555px;
  width: 17px;
}

.billing .not-available-9 {
  height: 17px;
  left: 99px;
  position: absolute;
  top: 612px;
  width: 17px;
}

.billing .not-available-10 {
  height: 17px;
  left: 99px;
  position: absolute;
  top: 1103px;
  width: 17px;
}

.billing .not-available-11 {
  height: 17px;
  left: 99px;
  position: absolute;
  top: 1222px;
  width: 17px;
}

.billing .x40mouser {
  left: 20px;
  letter-spacing: 0;
  line-height: 31.5px;
  position: absolute;
  top: 126px;
  white-space: nowrap;
}

.billing .x-icon-1 {
  height: 100px;
  left: 117px;
  position: absolute;
  top: 0;
  width: 100px;
}

.billing .group-21177 {
  height: 115px;
  left: 23px;
  position: absolute;
  top: 178px;
  width: 169px;
}

.billing .overlap-group2 {
  height: 1261px;
  left: 796px;
  position: absolute;
  top: -14px;
  width: 217px;
}

.billing .real-estate-1 {
  left: 20px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 84px;
  white-space: nowrap;
}

.billing .rectangle-62 {
  height: 1217px;
  left: 0;
  position: absolute;
  top: 44px;
  width: 215px;
}

.billing .available-14 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 367px;
  width: 24px;
}

.billing .available-15 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 427px;
  width: 24px;
}

.billing .available-16 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 486px;
  width: 24px;
}

.billing .available-17 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 545px;
  width: 24px;
}

.billing .available-18 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 604px;
  width: 24px;
}

.billing .available-19 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 731px;
  width: 24px;
}

.billing .available-20 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 791px;
  width: 24px;
}

.billing .available-21 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 850px;
  width: 24px;
}

.billing .available-22 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 909px;
  width: 24px;
}

.billing .available-23 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 968px;
  width: 24px;
}

.billing .available-24 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 1154px;
  width: 24px;
}

.billing .not-available-12 {
  height: 17px;
  left: 99px;
  position: absolute;
  top: 1101px;
  width: 17px;
}

.billing .not-available-13 {
  height: 17px;
  left: 99px;
  position: absolute;
  top: 1220px;
  width: 17px;
}

.billing .x50mouser {
  left: 20px;
  letter-spacing: 0;
  line-height: 31.5px;
  position: absolute;
  top: 124px;
  white-space: nowrap;
}

.billing .group-21178 {
  height: 115px;
  left: 23px;
  position: absolute;
  top: 176px;
  width: 169px;
}

.billing .last-section {
  height: 1263px;
  left: 1019px;
  position: absolute;
  top: -16px;
  width: 215px;
}

.billing .available-25 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 490px;
  width: 24px;
}

.billing .available-26 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 549px;
  width: 24px;
}

.billing .available-27 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 608px;
  width: 24px;
}

.billing .available-28 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 1099px;
  width: 24px;
}

.billing .available-29 {
  height: 24px;
  left: 96px;
  position: absolute;
  top: 1217px;
  width: 24px;
}

.billing .contact-us {
  left: 21px;
  letter-spacing: 0;
  line-height: 31.5px;
  position: absolute;
  top: 126px;
  white-space: nowrap;
}

.billing .group-21179 {
  height: 50px;
  left: 21px;
  position: absolute;
  top: 178px;
  width: 169px;
}
.mt-6 {
  margin-top: 4rem !important;
}
.mt-7 {
  margin-top: 5rem !important;
}
.mt-8 {
  margin-top: 6rem !important;
}
@media (max-width: 576px) {
  .pricing-cost {
    font-size: 14px !important;
  }
  .pricing-list li{
    line-height: 30px !important;
    margin-top: 30px !important;
    height: auto !important;
  }
  .pricing-buttons {
    margin-bottom: 20px !important;
  }
  .pricing-container {
    width: 100% !important;
  }
}
