// colors
//-----------------------------------------------------------
$white:             #ffffff;
$black:             #000000;
$transparent:       rgba(0, 0, 0, 0);
$currentColor:      currentColor;
// $red:               #d57872;
// $beige:             #f7f1e1;
// $green:             #29cb97;
// $yellow:            #e4b62c;
// $yellow-dark:       #c9ae58;
// $gray-pale:         #8a939f;
// $gray-dark:         #707070;
// $gray-light:        #e2e2e2;
// $blue-dark:         #2e4354;
// $placeholder-color: #e6e6e6;

// theme
//-----------------------------------------------------------
// $main-bg:           #f4f4f4;
// $aside-bg:          #7792aa;
// $theme-color:       #4d6982;
// $link-color:        #1e87f0;
$body-color:        #2e4354;
// $form-error:        $red;
// $form-success:      $green;
// $form-disabled:     $gray-light;

// Font Family
//-----------------------------------------------------------
$ff-arial: Arial, sans-serif;
$ff-helvetica-neue: 'HelveticaNeue', Arial, sans-serif;
$ff-helvetica-neue-condensed: 'HelveticaNeue Condensed', Arial, sans-serif;
$ff-default: $ff-arial;

// sizes
//-----------------------------------------------------------
$size:          8px;
// $aside-width:   $size * 22;
// $sidebar-width: $size * 40;

// header
//-----------------------------------------------------------
$header-height:            $size * 10;
$header-height-max-1280-1: $size * 8;
$header-height-max-768-1:  $size * 7;

// others
//-----------------------------------------------------------
$border-radius: 4px;