/* .c-stats
-----------------------------------------------------------*/
$r: ".c-stats";

// block
//-----------------------------------------------------------
#{$r} {
  background-color: #b4d0be;
  overflow: hidden;

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 32px;

    @media (max-width: 1440px - 1) {
      padding-left: 24px;
    }

    @media (max-width: 1280px - 1) {
      padding-left: 16px;
    }

    @media (max-width: 992px - 1) {
      display: flex;
      flex-direction: column-reverse;
      padding-left: 0;
    }

    @media (max-width: 768px - 1) {
      padding-left: 0;
    }
  }

  &__image {
    align-self: flex-end;
    margin-right: -32px;
    // margin-left: -16%;
    margin-bottom: -24px;

    @media (max-width: 1280px - 1) {
      margin-left: 0;
    }

    @media (max-width: 992px - 1) {
      margin-top: 24px;
      margin-left: -24px;
      margin-right: -24px;
    }

    @media (max-width: 480px - 1) {
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  &__body {
    align-self: center;
    width: 64%;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1280px - 1) {
      min-width: 420px;
      // width: 80%;
    }

    @media (max-width: 992px - 1) {
      width: auto;
      min-width: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 75%;
    }

    @media (max-width: 768px - 1) {
      max-width: 500px;
    }
  }

  &__title {
    font-size: 48px;
    font-weight: 600;
    line-height: 1.2;

    @media (max-width: 1440px - 1) {
      font-size: 40px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 36px;
      margin-right: 0;
    }

    @media (max-width: 992px - 1) {
      font-size: 32px;
    }

    @media (max-width: 768px - 1) {
      font-size: 28px;
    }

    // &--small {
    //   font-size: 24px;
    //   margin-top: 32px;
    //   margin-right: 32%;

    //   @media (max-width: 1280px - 1) {
    //     font-size: 20px;
    //     margin-top: 16px;
    //     margin-right: 0;
    //   }

    //   @media (max-width: 992px - 1) {
    //     font-size: 18px;
    //   }

    //   @media (max-width: 768px - 1) {
    //     font-size: 16px;
    //   }
    // }
  }

  &__content {
    // margin-right: 32%;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 16px;

    @media (max-width: 1280px - 1) {
      font-size: 14px;
      margin-top: 8px;
      margin-right: 0;
    }

    @media (max-width: 768px - 1) {
      font-size: 14px;
    }

    // &--small {
    //   font-size: 14px;
    //   margin-top: 8px;

    //   @media (max-width: 1280px - 1) {
    //     margin-right: 30%;
    //   }

    //   @media (max-width: 992px - 1) {
    //     margin-right: 0%;
    //   }
    // }
  }

  &__grid {
    display: flex;
    margin-top: 24px;
    font-size: 14px;

    @media (max-width: 1280px - 1) {
      margin-top: 12px;
    }

    @media (max-width: 480px - 1) {
      font-size: 12px;
      max-width: 320px;
    }

    &--item {
      width: 160px;
      padding-right: 4%;

      @media (max-width: 480px - 1) {
        width: 50%;
      }
    }

    &--top {
      font-size: 48px;
      font-weight: 600;
      line-height: 1.14;
      color: #0e6c75;

      @media (max-width: 1280px - 1) {
        font-size: 38px;
      }

      @media (max-width: 992px - 1) {
        font-size: 32px;
      }

      @media (max-width: 768px - 1) {
        font-size: 28px;
      }
    }
  }

  // modifier
  //---------------------------------------------------------
  &--papyrus {
    background-color: #e6dcbd;
  }
}

// influence
//-----------------------------------------------------------
