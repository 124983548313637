/* .c-about-us
-----------------------------------------------------------*/
$r: ".c-about-us";

// block
//-----------------------------------------------------------
#{$r} {
  overflow: hidden;
  background-color: #bdd8e6;
  background-image: url(../images/right-path-bg.svg);
  background-position: 100% 100%;
  background-size: auto 85%;
  background-repeat: no-repeat;

  @media (max-width: 768px - 1) {
    background-size: 100% auto;
  }

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    align-items: stretch;
    min-height: 56px * 8;
    padding-left: 32px;
    padding-top: 80px;
    @media (max-width: 1680px - 1) {
      min-height: 52px * 8;
    }

    @media (max-width: 1440px - 1) {
      min-height: 48px * 8;
      padding-left: 24px;
    }

    @media (max-width: 1280px - 1) {
      padding-left: 16px;
    }

    @media (max-width: 768px - 1) {
      min-height: 0;
      padding-bottom: 70%;
      padding-left: 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 640px);
    padding-right: 290px;
    background-image: url(#{$PATH_TO_IMAGES}/about-us-canvas.svg), url(#{$PATH_TO_IMAGES}/about-us-canvas-2.svg);
    background-repeat: no-repeat,  no-repeat;
    background-position: 100% 0, 100% 9999em;
    background-size: auto 100%, auto 100%;
    padding-top: 40px;
    padding-bottom: 24px;
    position: relative;

    @media (max-width: 1680px - 1) {
      width: calc(100% - 360px);
    }

    @media (max-width: 1280px - 1) {
      padding-right: 120px;
      background-position: 100% 9999em, 100% 0;
    }

    @media (max-width: 992px - 1) {
      width: calc(100% - 240px);
    }

    @media (max-width: 768px - 1) {
      background: none;
      background-color: $white;
      padding-right: 0;
      width: auto;
    }

    @media (max-width: 480px - 1) {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      width: 50vw;
      z-index: 0;
      background-color: $white;
    }

    &::after {
      @media (max-width: 768px - 1) {
        right: auto;
        left: 100%;
      }
    }
  }

  &__title {
    font-family: $ff-helvetica-neue-condensed;
    font-size: 64px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: capitalize;

    @media (max-width: 1680px - 1) {
      font-size: 60px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 56px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 52px;
    }

    @media (max-width: 992px - 1) {
      font-size: 44px;
    }

    @media (max-width: 768px - 1) {
      font-size: 28px;
    }
  }

  &__content {
    font-size: 22px;
    line-height: 1.6;
    margin-top: 8px;

    @media (max-width: 1680px - 1) {
      font-size: 20px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 18px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 16px;
    }

    @media (max-width: 992px - 1) {
      font-size: 14px;
    }

    @media (max-width: 768px - 1) {
      font-size: 14px;
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
