/* .c-nav
-------------------------------------------------------------*/
$r: ".c-nav";

// block
//-----------------------------------------------------------
#{$r} {
  display: flex;
  align-items: stretch;
  height: $header-height;
  background-color: $white;
  // box-shadow: 0 0 0px 1px rgba(0, 0, 0, 1);

  @media (max-width: 1280px - 1) {
    height: $header-height-max-1280-1;
  }

  @media (max-width: 768px - 1) {
    height: $header-height-max-768-1;
  }

  // element
  //---------------------------------------------------------
  &__container {
    display: flex;
    align-items: stretch;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    padding: 16px 0;
    width: 100%;

    @media (max-width: 1280px - 1) {
      padding: 8px + 2 0;
    }

    @media (max-width: 768px - 1) {
      padding: 8px 0;
    }
  }

  &__logo {
    display: block;
    height: 100%;

    img {
      display: block;
    }

    @media (max-width: 992px - 1) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: calc(100% - 2 * 10px);
    }

    @media (max-width: 992px - 1) {
      height: calc(100% - 2 * 8px);
    }
  }

  &__hamburger {
    order: 1;
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    // padding: 16px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity, filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: $transparent;
    display: none;

    @media (max-width: 992px - 1) {
      display: flex;
    }

    &:hover {
      opacity: .7;
    }

    .is-opened-menu & {
      &:hover {
        opacity: .7;
      }

      #{$r}__hamburger-inner {
        transition-delay: .12s;
        transition-timing-function: cubic-bezier(.215,.61,.355,1);
        transform: rotate(45deg);

        &,
        &:before,
        &:after {
          background-color: #2e4354;
        }

        &:before {
          top: 0;
          transition: top 75ms ease,opacity 75ms ease .12s;
          opacity: 0;
        }

        &:after {
          bottom: 0;
          transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
          transform: rotate(-90deg);
        }
      }
    }
  }

  &__hamburger-box {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 24px;
  }

  &__hamburger-inner {
    top: 50%;
    display: block;
    margin-top: -2px;
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
    transition-duration: 75ms;

    &,
    &:before,
    &:after {
      position: absolute;
      width: 32px;
      height: 8px - 4;
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform;
      background-color: #2e4354;
    }

    &:before {
      display: block;
      content: "";
      top: -10px;
      transition: top 75ms ease .12s,opacity 75ms ease;
    }

    &:after {
      display: block;
      content: "";
      bottom: -10px;
      transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
    }
  }

  &__list {
    display: flex;
    margin-left: auto;
    font-family: $ff-helvetica-neue;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 32px;

    @media (max-width: 1280px - 1) {
      font-size: 12px;
    }

    @media (max-width: 992px - 1) {
      flex-direction: column;
      display: none;
      background: $white;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;
      margin-right: 0;
    }

    .is-opened-menu & {
      @media (max-width: 992px - 1) {
        display: flex;
      }
    }
  }

  &__item {
    color: #2e4354;
    padding: 8px 8px + 4;
    transition: opacity .2s;

    @media (max-width: 992px - 1) {
      padding: 16px 24px;
    }

    @media (max-width: 480px - 1) {
      padding: 12px 16px;
    }

    &:hover {
      opacity: 0.8;
    }

    & + & {
      margin-left: 24px;

      @media (max-width: 992px - 1) {
        margin-left: 0;
      }
    }
  }

  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: -2;

    .is-opened-menu & {
      @media (max-width: 992px - 1) {
        display: block;
      }
    }
  }

  &__button {
    & + & {
      margin-left: 16px;
    }
  }

  &__sign-in-button {
    @media (max-width: 992px - 1) {
      order: 2;
      margin-left: auto;
    }
  }

  &__get-started-button {
    @media (max-width: 992px - 1) {
      display: none;
    }
  }


  // modifier
  //--------------------------------------------------------------

}
// influence
//-----------------------------------------------------------
