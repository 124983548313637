// Arial
//-----------------------------------------------------------
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Arial";
  src: url("#{$PATH_TO_FONTS}/Arial__400.woff") format("woff");
}

@font-face {
  font-weight: 400;
  font-style: italic;
  font-family: "Arial";
  src: url("#{$PATH_TO_FONTS}/Arial__400-italic.woff") format("woff");
}

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: "Arial";
  src: url("#{$PATH_TO_FONTS}/Arial__600.woff") format("woff");
}

@font-face {
  font-weight: 600;
  font-style: italic;
  font-family: "Arial";
  src: url("#{$PATH_TO_FONTS}/Arial__600-italic.woff") format("woff");
}

@font-face {
  font-weight: 900;
  font-style: normal;
  font-family: "Arial";
  src: url("#{$PATH_TO_FONTS}/Arial__900.woff") format("woff");
}

// HelveticaNeue
//-----------------------------------------------------------

@font-face {
  font-family: 'HelveticaNeue';
  src: url('#{$PATH_TO_FONTS}/HelveticaNeueCyr-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('#{$PATH_TO_FONTS}/HelveticaNeueCyr-Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('#{$PATH_TO_FONTS}/HelveticaNeueCyr-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('#{$PATH_TO_FONTS}/HelveticaNeueCyr-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('#{$PATH_TO_FONTS}/HelveticaNeueCyr-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('#{$PATH_TO_FONTS}/HelveticaNeueCyr-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('#{$PATH_TO_FONTS}/HelveticaNeueCyr-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('#{$PATH_TO_FONTS}/HelveticaNeueCyr-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeue Condensed';
  src: url('#{$PATH_TO_FONTS}/HelveticaNeue-CondensedRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue Condensed';
  src: url('#{$PATH_TO_FONTS}/HelveticaNeue-CondensedBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}