/* .l-app
-------------------------------------------------------------*/
$r: ".l-app";

// base
//-----------------------------------------------------------
#{$r} {
  scroll-behavior: smooth;
  padding-top: $header-height;

  @media (max-width: 1280px - 1) {
    padding-top: $header-height-max-1280-1;
  }

  @media (max-width: 768px - 1) {
    padding-top: $header-height-max-768-1;
  }

  // modifiers
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
