/* .c-form
-----------------------------------------------------------*/
$r: ".c-form";

// block
//-----------------------------------------------------------
#{$r} {

  // element
  //---------------------------------------------------------
  &__inner {
    > * {
      margin-top: 12px;

      #{$r}--lg & {
        margin-top: 24px;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
    color: #333333;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;

    &--1 {
      #{$r}__col {
        width: 100%;
      }
    }

    &--2 {
      #{$r}__col {
        width: calc(50% - 6px);
        margin-top: 12px;
        margin-right: 12px;

        #{$r}--lg & {
          width: calc(50% - 12px);
          margin-top: 24px;
          margin-right: 24px;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:nth-of-type(-n + 2) {
          margin-top: 0;
        }
      }
    }
  }

  &__col {
    display: flex;

    &--flex-end {
      justify-content: flex-end;
    }

    &--space-between {
      justify-content: space-between;
    }
  }

  &__success-block,
  &__error-block {
    display: none;
    border-radius: $border-radius;
    text-align: center;
  }

  &__success-block {
    padding: 16px;
    background-color: #dddddd;
  }

  &__error-block {
    margin-top: 12px;
    padding: 8px 12px;
    background-color: #ffdede;
  }


  // modifier
  //---------------------------------------------------------

  &.is-success {
    #{$r}__inner {
      display: none;
    }
  }

  &.is-success {
    #{$r}__success-block {
      display: block;
    }
  }

  &.is-error {
    #{$r}__error-block {
      display: block;
    }
  }
}

// influence
//-----------------------------------------------------------
