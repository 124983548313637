/* .c-question
-----------------------------------------------------------*/
$r: ".c-question";

// block
//-----------------------------------------------------------
#{$r} {
  background: rgb(240,235,217);
  background-image: url(#{$PATH_TO_IMAGES}/panorama.svg),
                    linear-gradient(0deg, rgba(240,235,217,1) 0%, rgba(235,235,219,1) 57%, rgba(212,228,218,1) 100%);
  background-repeat: no-repeat, no-repeat;
  background-position: 50% 0, 0 0;
  background-size: cover, auto auto;
  overflow: hidden;

  // element
  //---------------------------------------------------------

  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-bottom: 32px;
    padding-left: calc(24% - 294px);
    padding-right: calc(24% - 294px);

    @media (max-width: 992px - 1) {
      margin-left: auto;
      margin-right: auto;
      max-width: 75%;
      flex-direction: column;
    }

    @media (max-width: 768px - 1) {
      max-width: 500px;
    }
  }

  &__body {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    padding-right: calc(24% - 294px);
    padding-bottom: 24px;
    padding-top: 80px;
  }

  &__title {
    font-family: $ff-helvetica-neue-condensed;
    font-size: 64px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 16px;
    text-transform: capitalize;

    @media (max-width: 1680px - 1) {
      font-size: 56px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 48px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 40px;
    }

    @media (max-width: 768px - 1) {
      font-size: 36px;
    }

    @media (max-width: 480px - 1) {
      font-size: 28px;
    }
  }

  &__content {
    font-family: $ff-helvetica-neue;
    font-size: 28px;
    font-weight: 700;

    @media (max-width: 1680px - 1) {
      font-size: 24px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 20px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 18px;
    }

    @media (max-width: 768px - 1) {
      font-size: 16px;
    }

    @media (max-width: 768px - 1) {
      font-size: 14px;
    }
  }

  &__contacts {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-left: -48px;
  }

  &__form {
    margin-top: 96px;
    flex: none;
    width: 40%;
    margin-left: 8%;

    @media (max-width: 1680px - 1) {
      width: 44%;
      margin-left: 4%;
      overflow: hidden;
    }

    @media (max-width: 992px - 1) {
      margin-left: 0;
      margin-top: 32px;
      width: 100%;
    }

    input[type="submit"] {
      @media (max-width: 480px - 1) {
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------

#{$r} {
  .c-contact {
    $r-contact: '.c-contact';

    flex: none;
    color: $body-color;
    margin-top: 48px;
    margin-left: 48px;

    @media (max-width: 1280px - 1) {
      margin-top: 24px;
    }

    &__title {
      font-size: 24px;
      font-weight: 600;
      color: inherit;

      @media (max-width: 1680px - 1) {
        font-size: 22px;
      }

      @media (max-width: 1280px - 1) {
        font-size: 20px;
      }

      @media (max-width: 768px - 1) {
        font-size: 18px;
      }
    }

    &__item {
      font-family: $ff-helvetica-neue;
      font-size: 20px;
      color: inherit;

      @media (max-width: 1680px - 1) {
        font-size: 18px;
      }

      @media (max-width: 1280px - 1) {
        font-size: 16px;
      }

      @media (max-width: 768px - 1) {
        font-size: 14px;
      }
    }

    &__sub-title {
      font-weight: 400;
    }

    &__row {
      font-style: normal;
    }
  }

  &__form {
    .c-form {
      $form: ".c-form";

      &__row--2 {
        @media (max-width: 480px - 1) {
          /**/

          #{$form}__col {
            width: calc(100% - 0px);
            margin-top: 12px;
            margin-right: 12px;

            &:nth-child(1n) {
              margin-right: 0;
            }

            &:nth-of-type(-n + 1) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
