/* screen - real-estate */
.real-estate-list {
  padding-left: 30px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  list-style-type: circle;
  line-height: 180%;
}
.real-estate {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 2240px;
  padding: 88px 0;
  width: 1920px;
}

.real-estate .text-36 {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-left: 153px;
  min-height: 25px;
  white-space: nowrap;
}

.real-estate .flex-row {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  height: 1763px;
  margin-top: 77px;
  min-width: 1891px;
}

.real-estate .overlap-group4 {
  align-self: flex-end;
  height: 1721px;
  margin-bottom: 0;
  position: relative;
  width: 318px;
}

.real-estate .side-menu {
  height: 1721px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 316px;
}

.real-estate .fa-qs {
  left: 40px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 764px;
  white-space: nowrap;
}

.real-estate .industries {
  left: 39px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 511px;
  white-space: nowrap;
}

.real-estate .solutions {
  left: 39px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 286px;
  white-space: nowrap;
}

.real-estate .pricing {
  left: 39px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 221px;
  white-space: nowrap;
}

.real-estate .overview {
  left: 39px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 148px;
  white-space: nowrap;
}

.real-estate .nav-item {
  display: flex;
  height: 22px;
  left: 47px;
  position: absolute;
  top: 561px;
  width: 111px;
}

.real-estate .home {
  height: 18px;
  margin-left: -0.1px;
  margin-top: 2px;
  width: 20px;
}

.real-estate .real-estate-1 {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 11.1px;
  white-space: nowrap;
}

.real-estate .nav-item-1 {
  display: flex;
  height: 22px;
  left: 47px;
  position: absolute;
  top: 702px;
  width: 130px;
}

.real-estate .icon-1 {
  height: 15px;
  margin-left: 0;
  margin-top: 5.0px;
  width: 18px;
}

.real-estate .surname {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 7.0px;
  white-space: nowrap;
}

.real-estate .nav-item-2 {
  display: flex;
  height: 22px;
  left: 47px;
  position: absolute;
  top: 655px;
  width: 66px;
}

.real-estate .icon {
  height: 18px;
  margin-left: 0;
  margin-top: 2.0px;
  width: 18px;
}

.real-estate .place {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 9.0px;
  white-space: nowrap;
}

.real-estate .nav-item-3 {
  display: flex;
  height: 22px;
  left: 47px;
  position: absolute;
  top: 608px;
  width: 176px;
}

.real-estate .ngo-government {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 11.0px;
  white-space: nowrap;
}

.real-estate .nav-item-4 {
  display: flex;
  height: 44px;
  left: 47px;
  position: absolute;
  top: 427px;
  width: 241px;
}

.real-estate .nav-icon-code {
  height: 16px;
  margin-left: -0.5px;
  margin-top: 4px;
  width: 22px;
}

.real-estate .text-37 {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 9.5px;
}

.real-estate .nav-item-5 {
  display: flex;
  height: 66px;
  left: 46px;
  position: absolute;
  top: 336px;
  width: 242px;
}

.real-estate .nav-link-blockchain {
  height: 18px;
  margin-left: -0.5px;
  margin-top: 2.0px;
  width: 22px;
}

.real-estate .text-38 {
  letter-spacing: 0;
  line-height: 22.4px;
  margin-left: 11.5px;
}

.real-estate .arrow-up {
  height: 8px;
  left: 124px;
  position: absolute;
  top: 296px;
  width: 12px;
}

.real-estate .vector-1 {
  height: 8px;
  left: 129px;
  position: absolute;
  top: 521px;
  width: 12px;
}

.real-estate .docuwalk {
  height: 44px;
  left: 41px;
  position: absolute;
  top: 46px;
  width: 177px;
}

.real-estate .active {
  background-color: var(--sunglow);
  height: 3px;
  left: -11px;
  position: absolute;
  top: 159px;
  transform: rotate(90.00deg);
  width: 25px;
}

.real-estate .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 70px;
  min-height: 1638px;
  width: 1503px;
}

.real-estate .flex-row-1 {
  align-items: flex-end;
  display: flex;
  height: 427px;
  min-width: 1503px;
}

.real-estate .group-21175 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 21.0px;
  min-height: 326px;
  width: 482px;
}

.real-estate .group-21094 {
  align-items: flex-start;
  background-color: var(--sail);
  border-radius: 10px;
  box-shadow: 4px 4px 20px #00000026;
  display: flex;
  height: 80px;
  justify-content: flex-end;
  min-width: 80px;
  padding: 13.1px 10.7px;
}

.real-estate .group {
  height: 52px;
  width: 58px;
}

.real-estate .overlap-group2 {
  height: 54px;
  margin-top: 24px;
  position: relative;
  width: 196px;
}

.real-estate .group-21129 {
  height: 20px;
  left: 74px;
  position: absolute;
  top: 34px;
  width: 122px;
}

.real-estate .real-estate-2 {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.real-estate .text-31 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 34px;
  min-height: 54px;
  width: 478px;
}

.real-estate .overlap-group3 {
  align-items: flex-end;
  align-self: flex-end;
  background-color: var(--firefly);
  border-radius: 10px;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  margin-right: 6px;
  margin-top: 30px;
  min-width: 120px;
  padding: 12px;
}

.real-estate .get-started {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  min-width: 95px;
  white-space: nowrap;
}

.real-estate .screenshot-2021-08-03-at-1717-1 {
  height: 385px;
  margin-left: 167px;
  object-fit: cover;
  width: 654px;
}

.real-estate .group-21145 {
  align-self: flex-start;
  height: 188px;
  margin-left: 10px;
  width: 190px;
}

.real-estate .flex-row-2 {
  align-items: flex-start;
  display: flex;
  height: 1119px;
  margin-top: 92px;
  min-width: 1503px;
}

.real-estate .flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 1119px;
  width: 882px;
}

.real-estate .overlap-group9 {
  height: 53px;
  position: relative;
  width: 470px;
}

.real-estate .group-21132 {
  height: 20px;
  left: 348px;
  position: absolute;
  top: 33px;
  width: 122px;
}

.real-estate .text-29 {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.real-estate .text-30 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 26px;
  min-height: 189px;
  width: 673px;
}

.real-estate .flex-row-3 {
  align-items: flex-start;
  display: flex;
  margin-top: 62px;
  min-width: 709px;
}

.real-estate .overlap-group8 {
  align-items: center;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 110px;
  justify-content: flex-end;
  min-width: 341px;
  padding: 0 21px;
}

.real-estate .text-3 {
  letter-spacing: 0;
  line-height: 27px;
  min-height: 54px;
  width: 295px;
}

.real-estate-li {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  padding: 26px 21px;
}

.real-estate .text-3-1 {
  letter-spacing: 0;
  line-height: 27px;
  min-height: 81px;
  width: 295px;
}

.real-estate .flex-row-4 {
  align-items: flex-end;
  display: flex;
  margin-left: 1px;
  min-width: 709px;
}

.real-estate .overlap-group7 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 135px;
  justify-content: flex-end;
  min-width: 341px;
  padding: 26px 21px;
}

.real-estate .overlap-group5 {
  align-items: center;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 110px;
  justify-content: flex-end;
  margin-left: 27px;
  min-width: 341px;
  padding: 0 21px;
}

.real-estate .overlap-group6 {
  height: 54px;
  margin-top: 113px;
  position: relative;
  width: 333px;
}

.real-estate .group-37 {
  height: 20px;
  left: 211px;
  position: absolute;
  top: 34px;
  width: 122px;
}

.real-estate .customer-spotlight {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.real-estate .flex-row-5 {
  align-items: flex-start;
  display: flex;
  height: 295px;
  margin-top: 57px;
  min-width: 882px;
}

.real-estate .overlap-group1 {
  height: 291px;
  position: relative;
  width: 395px;
}

.real-estate .vector {
  height: 268px;
  left: 0;
  position: absolute;
  top: 24px;
  width: 395px;
}

.real-estate .vector-2 {
  height: 18px;
  left: 39px;
  position: absolute;
  top: 218px;
  width: 179px;
}

.real-estate .image {
  height: 40px;
  left: 39px;
  position: absolute;
  top: 0;
  width: 41px;
}

.real-estate .text-2 {
  left: 39px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 60px;
  width: 319px;
}

.real-estate .overlap-group {
  height: 291px;
  margin-left: 90px;
  position: relative;
  width: 395px;
}

.real-estate .group-1 {
  height: 24px;
  left: 39px;
  position: absolute;
  top: 195px;
  width: 133px;
}

.real-estate .group-21159 {
  display: flex;
  height: 569px;
  margin-left: 54px;
  margin-top: 76px;
  width: 567px;
}

.real-estate .group-21158 {
  align-items: flex-end;
  display: flex;
  height: 569.29345703125px;
  min-width: 567px;
}

.real-estate .overlap-group-1 {
  height: 570px;
  margin-bottom: -0.27px;
  position: relative;
  width: 567px;
}

.real-estate .mask-group {
  height: 157px;
  left: 0;
  position: absolute;
  top: 0;
  width: 319px;
}

.real-estate .overlap-group-item {
  height: 486px;
  left: 71px;
  position: absolute;
  top: 62px;
  width: 496px;
}

.real-estate .vector-2-1 {
  height: 116px;
  left: 402px;
  position: absolute;
  top: 454px;
  width: 114px;
}
