/* screen - home */
.products-container {
  padding: 20px 40px 20px 40px;
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.products-container-sm {
  margin-top: -40px;
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
  width: 80px;
  height: 80px;
  vertical-align: middle;
}
.products-container-sm img{
  display: block;
  vertical-align: middle;
  height: 100%
}

.main-conatiner {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.heading-small{
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: 26px;
}
.nav-section {
  align-items: flex-start;
  display: flex;
  height: 89px;
  margin-left: 88.0px;
  min-width: 1728px;
  padding: 0 0.0px;
}

.home .sz-logo {
  height: 80px;
  margin-top: -0.18px;
  width: 118px;
}

.home .overlap-group15 {
  align-items: flex-start;
  align-self: flex-end;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  margin-left: 159px;
  min-height: 80px;
  padding: 0 72.0px;
  width: 1435px;
}

.home .flex-row {
  align-items: center;
  align-self: flex-end;
  display: flex;
  margin-top: 15px;
  min-width: 1273px;
}

.home .heading {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-top: 1.0px;
  min-height: 25px;
  min-width: 51px;
  white-space: nowrap;
}

.home .heading-1 {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-left: 48px;
  margin-top: 1.0px;
  min-height: 25px;
  min-width: 77px;
  white-space: nowrap;
}

.home .products {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-left: 48px;
  margin-top: 1.0px;
  min-height: 25px;
  min-width: 74px;
  white-space: nowrap;
}

.home .flex-row-item {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-left: 48px;
  margin-top: 1.0px;
  min-height: 25px;
  min-width: 52px;
  white-space: nowrap;
}

.home .place {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-left: 48px;
  margin-top: 1.0px;
  min-height: 25px;
  min-width: 66px;
  white-space: nowrap;
}

.home .btn-text-1 {
  letter-spacing: 0;
  line-height: 25.2px;
  white-space: nowrap;
}

.featured-section {
  position: relative;
}

.home .text-accent {
  height: 21px;
  width: 122px;
  margin-left: -100px;
  margin-top: 25px;
}

.home .featured-text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.home .heading-2 {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: 48px;
  font-weight: 800;
  letter-spacing: 0;
}

.home .text-8 {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: 27px;
  font-weight: 600;
  letter-spacing: 0;
  z-index: 0;
  line-height: 37.8px;
  margin-top: 32px;
  white-space: nowrap;
}

.home .featured-hero {
  text-align: right;
}

.home .clients-section {
  align-items: center;
  display: flex;
  margin-top: 48px;
  min-width: 1174px;
}

.client-logo {
  width:  150px;
  height: 150px;
 object-fit:scale-down;
}
.home .about-us-section {
  align-items: flex-start;
  display: flex;
  height: 430px;
  margin-left: 136.31px;
  margin-top: 99px;
  min-width: 1398px;
}

.home .overlap-group16 {
  position: relative;
}

.home .accent {
  height: 231px;
  left: 0;
  position: absolute;
  top: 0;
  width: 112px;
}

.home .heading-3 {
  letter-spacing: 0;
  line-height: 45px;
  padding-left: 80px;
}

.home .text-9 {
  letter-spacing: 0;
  line-height: 40px;
  padding-left: 80px;
}

.home .overlap-group17 {
  background-size: 100% 100%;
  background: #FEF6EE;
  border-radius: 20px;
  width : 70%;
}

.home .text-10 {
  letter-spacing: 0;
  line-height: 35.1px;
  padding: 40px;
  text-align: center;
  word-wrap: break-word;
}

.home .span1 {
  line-height: 29.2px;
}

.home .products-section {
  align-items: flex-end;
  display: flex;
  height: 371px;
  margin-right: 217.0px;
  margin-top: 140px;
  min-width: 1147px;
}

.home .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 371px;
  width: 341px;
}

.home .overlap-group21 {
}

.home .text-accent-1 {
  height: 21px;
  left: 114px;
  z-index: -1;
  position: absolute;
  top: 34px;
  width: 122px;
}

.home .heading-4 {
  letter-spacing: 0;
  line-height: 50.4px;
  white-space: nowrap;
}

.home .overlap-group19 {
  height: 277px;
  position: relative;
  width: 341px;
}

.home .product-bg-large {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 270px;
  width: 341px;
}

.home .product-bg {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 4px 4px 20px #00000026;
  height: 80px;
  width: 80px;
}

.home .heading-5 {
  letter-spacing: 0;
  line-height: 25.2px;
  white-space: nowrap;
}

.home .text {
  letter-spacing: 0;
  line-height: 27px;
}

.learn-more-btn {
  align-items: center;
  display: flex;
  height: 25px;
}

.learn-more {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  min-width: 95px;
  text-align: right;
  white-space: nowrap;
}
 .plus {
  height: 14px;
  margin-bottom: 1.0px;
  margin-left: 9px;
  width: 14px;
}


.home .text-12 {
  left: 26px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 135px;
  width: 295px;
}

.learn-more-btn-1 {
  align-items: center;
  display: flex;
  height: 25px;
  left: 201px;
  position: absolute;
  top: 231px;
}

.home .plus-1 {
  height: 14px;
  margin-bottom: 1.0px;
  margin-left: 9px;
  width: 14px;
}

.home .heading-6 {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 99px;
  white-space: nowrap;
}

.home .docuwalk {
  height: 49px;
  left: 40px;
  position: absolute;
  top: 16px;
  width: 51px;
}

.home .plus-2 {
  height: 14px;
  margin-bottom: 1.0px;
  margin-left: 9px;
  width: 14px;
}

.home .document-gps {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 99px;
  white-space: nowrap;
}

.home .document-gps-1 {
  height: 57px;
  left: 38px;
  position: absolute;
  top: 11px;
  width: 53px;
}

.home .solutions-section {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  height: 836px;
  margin-right: -36px;
  margin-top: 131px;
  min-width: 1678px;
}

.home .flex-row-1 {
  align-items: flex-end;
  align-self: flex-start;
  display: flex;
  height: 746px;
  min-width: 710px;
}

.home .flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 746px;
  width: 341px;
}

.home .overlap-group4 {
  height: 54px;
  position: relative;
  width: 241px;
}

.home .text-accent-2 {
  height: 21px;
  left: 119px;
  position: absolute;
  top: 33px;
  width: 122px;
}

.home .text-13 {
  letter-spacing: 0;
  line-height: 50.4px;
  white-space: nowrap;
}

.home .overlap-group5 {
  height: 143px;
  margin-top: 40px;
  position: relative;
}
.product-solution-wrapper{
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
}
.product-solution-text-wrapper{
  padding: 20px;
}
.product-solutions-icon img{
  margin-top: -40px;
}
.home .more-icon {
  height: 14px;
  width: 14px;
}
.more-icon-wrapper{
  padding-bottom: 20px;
  text-align: right;
}
.home .lock {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .overlap-group1 {
  height: 163px;
  margin-top: 18px;
  position: relative;
  width: 341px;
}

.home .solutions-bg {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 120px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 341px;
}

.home .text-14 {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: 17px;
  font-weight: 800;
  left: 25px;
  letter-spacing: 0;
  line-height: 22.1px;
  position: absolute;
  top: 95px;
}

.home .blockchain {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .more-icon-1 {
  height: 14px;
  left: 313px;
  position: absolute;
  top: 136px;
  width: 14px;
}

.home .overlap-group-1 {
  height: 143px;
  margin-top: 18px;
  position: relative;
  width: 341px;
}

.home .solutions-bg-2 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 341px;
}

.home .text-2 {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 95px;
  white-space: nowrap;
}

.home .vr {
  height: 100px;
  left: 9px;
  position: absolute;
  background: transparent;
  opacity: 1;
  top: 0;
  width: 100px;
}

.home .more-icon-2 {
  height: 14px;
  left: 313px;
  position: absolute;
  top: 117px;
  width: 14px;
}

.home .overlap-group-2 {
  height: 143px;
  margin-top: 24px;
  position: relative;
  width: 341px;
}

.home .solutions-bg-3 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 341px;
}

.home .city {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 28px;
  min-height: 652px;
  width: 341px;
}

.home .overlap-group7 {
  height: 143px;
  position: relative;
  width: 341px;
}

.home .solutions-bg-4 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 341px;
}

.home .chart {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .text-15 {
  left: 25px;
  letter-spacing: 0;
  line-height: 23.4px;
  position: absolute;
  top: 95px;
}

.home .code {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .solutions-bg-5 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 341px;
}

.home .gps {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .solutions-bg-6 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 341px;
}

.home .social {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .flex-col-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 82px;
  min-height: 726px;
  width: 341px;
}

.home .overlap-group13 {
  height: 56px;
  position: relative;
  width: 179px;
}

.home .text-accent-3 {
  height: 21px;
  left: 57px;
  position: absolute;
  top: 35px;
  width: 122px;
}

.home .text-16 {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home .overlap-group12 {
  height: 143px;
  margin-top: 38px;
  position: relative;
  width: 341px;
}

.home .industry-bg {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 341px;
}

.home .house {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .industry-bg-1 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 341px;
}

.home .text-17 {
  left: 25px;
  letter-spacing: 0;
  line-height: 23.4px;
  position: absolute;
  top: 95px;
  white-space: nowrap;
}

.home .cap {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .industry-bg-2 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 341px;
}

.home .microscope {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .industry-bg-3 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 341px;
}

.home .money {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .overlap-group1-1 {
  height: 632px;
  margin-left: 28px;
  position: relative;
  width: 481px;
}

.home .accent-1 {
  height: 428px;
  left: 44px;
  position: absolute;
  top: 119px;
  width: 437px;
}

.home .industry-bg-4 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 365px;
  width: 341px;
}

.home .industry-bg-5 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 532px;
  width: 341px;
}

.home .industry-bg-6 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 204px;
  width: 341px;
}

.home .industry-bg-7 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 100px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 341px;
}

.home .ngo {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 100px;
}

.home .text-18 {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 417px;
  white-space: nowrap;
}

.home .media {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 322px;
  width: 100px;
}

.home .text-19 {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 584px;
  white-space: nowrap;
}

.home .business {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 489px;
  width: 100px;
}

.home .text-20 {
  left: 25px;
  letter-spacing: 0;
  line-height: 23.4px;
  position: absolute;
  top: 256px;
  white-space: nowrap;
}

.home .legal {
  height: 100px;
  left: 9px;
  position: absolute;
  top: 161px;
  width: 100px;
}

.home .more-icon-3 {
  height: 14px;
  left: 313px;
  position: absolute;
  top: 439px;
  width: 14px;
}

.home .more-icon-4 {
  height: 14px;
  left: 313px;
  position: absolute;
  top: 278px;
  width: 14px;
}

.home .more-icon-5 {
  height: 14px;
  left: 313px;
  position: absolute;
  top: 606px;
  width: 14px;
}

.home .testimonial-section {
  align-items: flex-end;
  display: flex;
  height: 406px;
  margin-left: 8.0px;
  margin-top: 100px;
  min-width: 1372px;
}

.home .flex-col-4 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 402px;
  width: 395px;
}

.home .overlap-group25 {
  height: 56px;
  position: relative;
  width: 223px;
}

.home .text-accent-4 {
  height: 21px;
  left: 101px;
  position: absolute;
  top: 35px;
  width: 122px;
}

.home .heading-7 {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home .overlap-group24 {
  height: 292px;
  position: relative;
  width: 395px;
}

.home .testimonial-bg {
  height: 268px;
  left: 0;
  position: absolute;
  top: 24px;
  width: 395px;
}

.home .quote-icon {
  height: 40px;
  left: 39px;
  position: absolute;
  top: 0;
  width: 41px;
}

.home .text-3 {
  left: 39px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 60px;
  width: 319px;
}

.home .coldwell-1 {
  height: 40px;
  left: 39px;
  position: absolute;
  top: 193px;
  width: 134px;
}

.home .overlap-group23 {
  height: 292px;
  margin-bottom: 3.5px;
  position: relative;
  width: 395px;
}

.home .century-21 {
  height: 18px;
  left: 39px;
  position: absolute;
  top: 218px;
  width: 179px;
}

.home .overlap-group22 {
  height: 292px;
  margin-bottom: 3.5px;
  position: relative;
  width: 395px;
}

.home .group {
  height: 25px;
  left: 41px;
  position: absolute;
  top: 193px;
  width: 133px;
}

.home .latest-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: 140.0px;
  margin-top: 160px;
  min-height: 625px;
  width: 1224px;
}

.home .flex-col-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 423px;
  width: 1179px;
}

.home .flex-row-2 {
  align-items: flex-end;
  display: flex;
  height: 382px;
  min-width: 1179px;
}

.home .flex-col-6 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 320px;
  width: 322px;
}

.home .overlap-group26 {
  height: 51px;
  position: relative;
  width: 100%;
}

.home .text-accent-5 {
  height: 18px;
  left: 12px;
  position: absolute;
  top: 33px;
  width: 104px;
}

.home .heading-8 {
  width: 100%;
  letter-spacing: 0;
  line-height: 50.4px;
}

.home .text-21 {
  letter-spacing: 0;
  line-height: 28.1px;
}

.home .text-22 {
  letter-spacing: 0;
  line-height: 27px;
}

.home .date {
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 10px;
  min-height: 21px;
  white-space: nowrap;
}

.home .latest-image-large {
  height: 264px;
  margin-bottom: 8px;
  width: 470px;
  min-height: 164px;

}

.home .flex-col-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 37px;
  min-height: 272px;
  width: 322px;
}

.home .latestimage-sm {
  height: 180px;
  width: 321px;
}

.home .text-23 {
  letter-spacing: 0;
  line-height: 23.4px;
  margin-top: 15px;
  min-height: 46px;
  width: 322px;
}

.home .sep {
  height: 2px;
  margin-left: 3px;
  margin-top: 39px;
}

.home .flex-row-3 {
  align-items: flex-start;
  display: flex;
  height: 163px;
  margin-top: 39px;
  min-width: 1198px;
}

.home .flex-col-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: 279px;
}

.home .text-4 {
  letter-spacing: 0;
  line-height: 23.4px;
}

.home .flex-col-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 28px;
  min-height: 77px;
  width: 279px;
}

.home .text-5 {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 46px;
  width: 279px;
}

.home .flex-col-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 27px;
  min-height: 100px;
  width: 279px;
}

.home .flex-col-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 27px;
  min-height: 163px;
  width: 279px;
}

.home .view-more-btn {
  align-items: center;
  align-self: flex-end;
  display: flex;
  height: 25px;
  margin-right: 20px;
  margin-top: 61px;
  min-width: 114px;
}

.home .view-more {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  min-width: 91px;
  white-space: nowrap;
}

.home .vector {
  height: 14px;
  margin-bottom: 1.0px;
  margin-left: 9px;
  width: 14px;
}

.home .faq-section {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  height: 870px;
  margin-top: 64px;
  min-width: 2031px;
}

.home .faq-image {
  height: 669px;
  width: 535px;
}

.home .flex-col-12 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 75.0px;
  margin-left: 93px;
  min-height: 609px;
  width: 875px;
}

.home .overlap-group33 {
  position: relative;
  width: 100%;
}

.home .text-accent-6 {
  height: 21px;
  left: 346px;
  position: absolute;
  top: 33px;
  width: 122px;
}

.home .heading-9 {
  line-height: 50.4px;
}

.faq-container {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  margin-top: 25px;
  padding: 13px 17px;
}

.faq-content {
  width: 100%;
}

.home .text-24 {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}

.home .text-25 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 20px;
  min-height: 54px;
}

.home .faq-icon {
  width: 15px;
  height: 15px;
  min-width: 15px;
}

.home .text-6 {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  min-width: 538px;
  white-space: nowrap;
}

.home .expand-icon {
  align-self: center;
  height: 14px;
  margin-left: 286px;
  width: 14px;
}

.home .text-7 {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  min-width: 664px;
  white-space: nowrap;
}

.home .expand-icon-1 {
  align-self: center;
  height: 14px;
  margin-left: 160px;
  width: 14px;
}

.home .accent-2 {
  align-self: flex-end;
  height: 368px;
  margin-bottom: 0;
  margin-left: 207px;
  width: 258px;
}


.home .overlap-group27 {
  height: 518px;
  position: relative;
  width: 1304px;
}

.home .mail-bg {
  height: 518px;
  left: 355px;
  position: absolute;
  top: 0;
  width: 949px;
}

.home .text-accent-7 {
  height: 21px;
  left: 692px;
  position: absolute;
  top: 195px;
  width: 122px;
}

.home .text-26 {
  left: 495px;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 163px;
  white-space: nowrap;
}

.home .text-27 {
  left: 495px;
  letter-spacing: 0;
  line-height: 23.4px;
  position: absolute;
  top: 252px;
  white-space: nowrap;
}


.home .checkbox {
  align-items: flex-end;
  display: flex;
  height: 21px;
  left: 494px;
  position: absolute;
  top: 380px;
}

.home .rectangle-30 {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 19px;
  width: 19px;
}

.home .text-2-1 {
  letter-spacing: 0;
  line-height: 20.8px;
  margin-left: 14px;
  min-height: 21px;
  min-width: 194px;
  white-space: nowrap;
}

.home .email {
  left: 515px;
  letter-spacing: 0;
  line-height: 23.4px;
  position: absolute;
  top: 318px;
  white-space: nowrap;
}

.home .submit-btn {
  height: 50px;
  left: 1045px;
  position: absolute;
  top: 398px;
  width: 120px;
}


.home .sep-1 {
  height: 2px;
  margin-left: -1px;
  margin-top: -1px;
  width: 1642px;
}

.home .flex-row-4 {
  align-items: flex-start;
  display: flex;
  height: 141px;
  margin-top: 59px;
  min-width: 1523px;
}

.home .flex-col-14 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 141px;
  width: 118px;
}

.home .sz-logo-1 {
  height: 80px;
  margin-left: 0;
  width: 118px;
}

.home .text-28 {
  letter-spacing: 0;
  line-height: 20.8px;
  margin-top: 40px;
  min-height: 21px;
  opacity: 0.7;
  white-space: nowrap;
}

.home .text-29 {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 20.8px;
  margin-left: 20px;
  min-height: 21px;
  min-width: 97px;
  opacity: 0.7;
  white-space: nowrap;
}

.home .location {
  align-items: flex-start;
  display: flex;
  height: 50px;
  margin-left: 42px;
  margin-top: 0.18px;
  min-width: 235px;
}

.home .map-pin {
  height: 21px;
  width: 15px;
}

.home .address {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-left: 10px;
  min-height: 50px;
}

.home .flex-col-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  width: 52px;
}

.home .place-1 {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  white-space: nowrap;
}

.home .about {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-top: 20px;
  min-height: 25px;
  white-space: nowrap;
}

.home .flex-col-16 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 57px;
  min-height: 70px;
  width: 77px;
}

.home .solutions {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  white-space: nowrap;
}

.home .latest {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-top: 20px;
  min-height: 25px;
  white-space: nowrap;
}

.home .flex-col-17 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 58px;
  min-height: 70px;
  width: 74px;
}

.home .products-1 {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  white-space: nowrap;
}

.home .place-2 {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-top: 20px;
  min-height: 25px;
  white-space: nowrap;
}

.home .facebook-1 {
  height: 19px;
  margin-left: 87px;
  margin-top: 5.18px;
  width: 10px;
}

.home .twitter-1 {
  height: 15px;
  margin-left: 25px;
  margin-top: 7.1px;
  width: 18px;
}

.home .instagram-1 {
  height: 18px;
  margin-left: 25px;
  margin-top: 5.96px;
  width: 18px;
}

.home .linkedin-1 {
  height: 18px;
  margin-left: 25px;
  margin-top: 5.18px;
  width: 19px;
}

@media (max-width: 1680px) {

}

@media (max-width: 1440px) {

}

@media (max-width: 1280px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {

}
