/* .l-legal
-----------------------------------------------------------*/
$r: ".l-legal";

// block
//-----------------------------------------------------------
#{$r} {
  $aside: 24%;

  display: flex;

  @media (max-width: 768px - 1) {
    display: block;
  }

  // element
  //---------------------------------------------------------
  &__aside {
    width: $aside;
    margin-right: 24px;

    @media (max-width: 768px - 1) {
      width: auto;
      margin-bottom: 16px;
    }
  }

  &__main {
    flex: 1;
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
