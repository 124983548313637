/* .c-sign-up
-----------------------------------------------------------*/
$r: ".c-sign-up";

// block
//-----------------------------------------------------------
#{$r} {
  background-color: #cadcd1;
  background-image: url(#{$PATH_TO_IMAGES}/leaf-1.svg), url(#{$PATH_TO_IMAGES}/leaf-2.svg);
  background-position: 1% 88%, 90% 16%;
  background-repeat: no-repeat, no-repeat;
  background-size: 156px auto, 176px auto;

  @media (max-width: 1680px - 1) {
    background-position: 1% 88%, 92% 16%;
  }

  @media (max-width: 1440px - 1) {
    background-size: 140px auto, 154px auto;
    background-position: 1% 88%, 94% 16%;
  }

  @media (max-width: 1280px - 1) {
    background-size: 120px auto, 148px auto;
  }

  @media (max-width: 992px - 1) {
    background-position: calc(50% - 296px) 88%, calc(50% + 270px) 16%;
  }

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    padding-top: 64px;
    padding-bottom: 64px;

    @media (max-width: 1680px - 1) {
      padding-top: 56px;
      padding-bottom: 56px;
    }

    @media (max-width: 1440px - 1) {
      padding-top: 48px;
      padding-bottom: 48px;
    }

    @media (max-width: 992px - 1) {
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      max-width: 375px;
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  &__body {
    flex: 590;
    margin-left: 12%;
    padding-bottom: 32px;

    @media (max-width: 1680px - 1) {
      margin-left: 14%;
    }

    @media (max-width: 992px - 1) {
      margin-left: 0;
      padding-bottom: 16px;
    }
  }

  &__title {
    font-family: $ff-helvetica-neue;
    font-size: 56px;
    font-weight: 700;
    line-height: 1.3;

    @media (max-width: 1920px - 1) {
      font-size: 54px;
    }

    @media (max-width: 1680px - 1) {
      font-size: 44px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 40px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 32px;
    }

    @media (max-width: 1280px - 1) {
      margin-bottom: 8px;
    }
  }

  &__content {
    font-size: 24px;
    line-height: 1.3;

    @media (max-width: 1680px - 1) {
      font-size: 22px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 20px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 18px;
    }

    @media (max-width: 992px - 1) {
      font-size: 16px;
    }
  }

  &__form {
    flex: 400;
    margin-left: 8%;
    margin-right: 20%;

    @media (max-width: 1440px - 1) {
      margin-right: 16%;
    }

    @media (max-width: 1280px - 1) {
      margin-left: 4%;
      margin-right: 18%;
    }

    @media (max-width: 992px - 1) {
      margin-left: 0;
      margin-right: 0;
    }

    input[type="submit"] {
      @media (max-width: 768px - 1) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 16px;
      }

      @media (max-width: 480px - 1) {
        width: 100%;
        margin-top: 0;
      }
    }
  }


  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
