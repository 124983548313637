/* .c-field
-----------------------------------------------------------*/
$r: ".c-field";

// block
//-----------------------------------------------------------
#{$r} {
  background-color: $white;
  border-radius: 4px;
  border: solid 1px #dadada;
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 12px;
  font-family: Arial, sans-serif;
  color: #999999;

  ::placeholder {
    color: #999999;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    color: #999999;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #999999;
  }

  // element
  //---------------------------------------------------------

  // modifier
  //---------------------------------------------------------
  &--search {
    padding-right: 56px;
    // background-image: url(#{$PATH_TO_IMAGES}/icon-search.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) 50%;
    background-size: 16px 16px;
  }
}

textarea#{$r} {
  min-height: 128px;
}

// influence
//-----------------------------------------------------------
