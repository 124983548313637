/* .c-work
-----------------------------------------------------------*/
$r: ".c-work";

// block
//-----------------------------------------------------------
#{$r} {
  background-color: #e6dcbd;
  // background-image: url(#{$PATH_TO_IMAGES}/careers-work-bg.svg);
  background-repeat: no-repeat;
  background-position: 103% 100%;
  background-size: 20% auto;
  overflow: hidden;

  @media (max-width: 768px - 1) {
    background-image: none;
  }

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 768px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;

    @media (max-width: 768px - 1) {
      flex-direction: column-reverse;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__image {
    flex: 1;
    display: flex;
    justify-content: center;

    @media (max-width: 768px - 1) {
      margin-left: -18%;
    }
  }

  &__body {
    width: 50%;
    padding-left: 8%;
    padding-right: 8%;

    @media (max-width: 768px - 1) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      width: auto;
      text-align: center;
      margin-bottom: 32px;
    }
  }

  &__title {
    font-size: 72px;
    font-weight: 600;
    line-height: 1;
    color: #333333;
    margin-bottom: 32px;
    text-transform: capitalize;

    @media (max-width: 1280px - 1) {
      font-size: 64px;
    }

    @media (max-width: 992px - 1) {
      font-size: 56px;
    }

    @media (max-width: 768px - 1) {
      font-size: 48px;
      margin-bottom: 16px;
    }

    @media (max-width: 480px - 1) {
      font-size: 40px;
    }
  }

  &__controls {

  }

  // modifier
  //---------------------------------------------------------
  &--about-us {
    background: none;
    background-color: $white;
    background-image: url(#{$PATH_TO_IMAGES}/about-us-work-image.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% 100%;

    @media (max-width: 768px - 1) {
      background-size: 146% auto;
    }

    #{$r}__container {}

    #{$r}__inner {}

    #{$r}__body {
      margin-left: calc(24% - 294px);
      padding-left: 0;

      @media (max-width: 1280px - 1) {
        margin-left: 0;
        padding-right: 0;
      }

      @media (max-width: 768px - 1) {
        margin-bottom: 64px;
      }

      @media (max-width: 480px - 1) {
        margin-bottom: 48px;
      }
    }

    #{$r}__title {
      font-family: "HelveticaNeue", Arial;
      font-size: 48px;
      font-weight: 600;
      line-height: 1.14;
      color: #2e4354;
      margin-bottom: 8px;

      @media (max-width: 1280px - 1) {
        font-size: 40px;
      }

      @media (max-width: 992px - 1) {
        font-size: 32px;
      }
    }

    #{$r}__content {
      font-size: 16px;
      line-height: 1.6;
      color: #4d6882;
      margin-bottom: 16px;

      @media (max-width: 992px - 1) {
        font-size: 14px;
      }
    }
  }
}

// influence
//-----------------------------------------------------------
