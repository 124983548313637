/* .c-advantages
-----------------------------------------------------------*/
$r: ".c-advantages";

// block
//-----------------------------------------------------------
#{$r} {
  background-image: url(#{$PATH_TO_IMAGES}/advantages-bg.svg),
                    linear-gradient(0deg, #d7e7f0 0%, #bdd8e6 100%);
  background-repeat: no-repeat, no-repeat;
  background-position: 100% 100%, 0 0;
  background-size: auto 100%, auto auto;

  @media (max-width: 1440px) {
    background-size: auto 90%, auto auto;
  }

  @media (max-width: 992px) {
    background-size: 0 0, auto auto;
    background-position: -100px -100px, 0 0;
  }

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    padding-top: 40px;
    padding-bottom: 64px;

    @media (max-width: 992px) {
      padding-top: 32px;
      padding-bottom: 32px;
      margin-left: auto;
      margin-right: auto;
      max-width: 720px;
    }

    @media (max-width: 768px) {
      max-width: 500px;
    }
  }

  &__body {
    display: flex;

    @media (max-width: 1280px) {
      flex-direction: column;
      margin-left: calc(24% - 227px);
    }

    @media (max-width: 992px) {
      margin-left: 0;
    }
  }

  &__title {
    flex: 0 0 auto;
    font-family: $ff-helvetica-neue-condensed;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    width: 400px;

    @media (max-width: 1680px) {
      font-size: 40px;
      width: 320px;
    }

    @media (max-width: 1440px) {
      font-size: 30px;
      width: 248px;
    }

    @media (max-width: 1280px) {
      font-size: 32px;
      width: auto;
    }
  }

  &__content {
    flex: 1;
    margin-left: calc(14% - 194px);
    margin-right: calc(35% - 290px);

    @media (max-width: 1680px) {
      margin-left: calc(16% - 194px)
    }

    @media (max-width: 1440px) {
      margin-left: calc(17% - 194px);
      margin-right: calc(39% - 290px);
    }

    @media (max-width: 1280px) {
      margin-left: 0;
      margin-top: 24px;
      margin-right: calc(50% - 200px)
    }

    @media (max-width: 992px) {
      margin-right: 0;
    }
  }

  &__grid {
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    @media (max-width: 1680px) {
      font-size: 14px;
    }

    @media (max-width: 1280px) {
      font-size: 12px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &__grid-item {
    display: flex;
    flex-direction: column;
    width: 224px;

    @media (max-width: 1680px) {
      width: 264px;
    }

    @media (max-width: 1440px) {
      width: 244px;
    }

    @media (max-width: 1280px) {
      width: 194px;
    }

    @media (max-width: 992px) {
      width: 208px;
    }

    @media (max-width: 768px) {
      width: auto;
    }

    & + & {
      @media (max-width: 768px) {
        margin-top: 32px;
      }
    }
  }

  &__grid-title {
    font-family: $ff-helvetica-neue;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: capitalize;

    @media (max-width: 1680px) {
      font-size: 22px;
    }

    @media (max-width: 1440px) {
      font-size: 18px;
    }

    @media (max-width: 1280px) {
      font-size: 16px;
    }

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  &__grid-content {
    margin-top: 16px;
    margin-bottom: auto;
  }

  &__grid-controls {
    margin-top: 16px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &__grid-button {

  }



  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
