/* .c-employee
-----------------------------------------------------------*/
$r: ".c-employee";

// block
//-----------------------------------------------------------
#{$r} {
  display: flex;

  // element
  //---------------------------------------------------------
  a {
    color: #333333;
  }

  &__avatar {
    width: 128px;
    height: 128px;
    border-radius: 100%;
    background-size: contain;
    background-color: #efefef;
    background-repeat: no-repeat;
    // background-image: url(#{$PATH_TO_IMAGES}/user.svg);
    background-position: center center;
    object-fit: cover;
    overflow: hidden;
    margin-right: $size * 3;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__details {
    flex: 1;
  }

  &__name {
    font-weight: 600;
    line-height: 1;
    color: #4d6882;
    font-size: $size * 3 - 4;
  }

  &__position {
    color: #333333;
    font-weight: 600;
    line-height: 1;
    font-size: $size * 2 - 4;
    margin-top: $size - 4;
  }

  &__email {
    margin-top: $size + 4;
    font-size: $size * 2 - 2;
    font-weight: 600;
    cursor: pointer;
  }

  &__phone {
    font-size: $size * 2 - 2;
    cursor: pointer;
  }

  &__social {
    margin-top: $size + 4;
  }


  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
