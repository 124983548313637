/* .c-legal
-----------------------------------------------------------*/
$r: ".c-legal";

// block
//-----------------------------------------------------------
#{$r} {
  background-color: $white;
  padding: 32px;
  word-spacing: 0.1em;

  @media (max-width: 768px - 1) {
    padding: 16px;
  }

  // element
  //---------------------------------------------------------

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: $ff-helvetica-neue;
    font-weight: 500;
    color: #333333;
    margin-top: 0;
    line-height: 1.2;
  }

  p,
  ul,
  ol {
    color: #333333;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  h1,
  .h1 {
    font-size: 48px;
  }

  h2,
  .h2 {
    font-size: 40px;
  }

  h3,
  .h3 {
    font-size: 32px;
  }

  h4,
  .h4 {
    font-size: 24px;
    font-weight: 600;
  }

  h5,
  .h5 {
    font-size: 16px;
    font-weight: 600;
  }

  h6,
  .h6 {
    font-size: 14px;
    font-weight: 600;
  }

  .h-center {
    text-align: center;
  }

  @media (max-width: 992px - 1) {
    h1,
    .h1 {
      font-size: 40px;
    }
  }

  @media (max-width: 768px - 1) {
    h1,
    .h1 {
      font-size: 32px;
    }
  }

  @media (max-width: 480px - 1) {
    h1,
    .h1 {
      font-size: 24px;
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
