/* .l-header
-------------------------------------------------------------*/
$r: ".l-header";

// base
//-----------------------------------------------------------
#{$r} {
  flex: none;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  position: fixed;

  // element
  //---------------------------------------------------------

  // modifiers
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
