/* .c-partners-and-customers
-----------------------------------------------------------*/
$r: ".c-partners-and-customers";

// block
//-----------------------------------------------------------
#{$r} {

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;

    @media (max-width: 992px - 1) {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    @media (max-width: 768px - 1) {
      flex-wrap: wrap;
    }
  }

  &__item {
    margin-right: 28px;
    margin-left: 28px;
    padding-top: 16px;
    padding-bottom: 16px;

    @media (max-width: 1280px - 1) {
      margin-left: 24px;
      margin-right: 24px;
    }

    @media (max-width: 992px - 1) {
      margin-left: 16px;
      margin-right: 16px;
    }

    @media (max-width: 768px - 1) {
      flex: 1;
      flex-basis: 75px;
    }

    @media (max-width: 768px - 1) {
      flex-basis: 85px;
      max-width: 96px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
