/* .c-learn-about-platform
-----------------------------------------------------------*/
$r: ".c-learn-about-platform";

// block
//-----------------------------------------------------------
#{$r} {
  background-color: #e6dcbd;

  // element
  //---------------------------------------------------------
  &__inner.h-container {
    display: -ms-grid;
    display: grid;
    max-width: 1440px;
    padding-top: 24px;
    padding-bottom: 24px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 4fr 3fr;
    grid-template-columns: 4fr 3fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    margin: 0 auto;
    padding-right: 32px;
    padding-left: 32px;

    @media screen and (min-width: 1440px) {
      -ms-grid-columns: 4fr 3fr;
      grid-template-columns: 4fr 3fr;
    }

    @media screen and (min-width: 1680px) {
      max-width: 1680px;
    }
  
    @media screen and (min-width: 1920px) {
      // max-width: 1920px;
      padding-right: 64px;
      padding-left: 64px;
      padding-top: 40px;
      padding-bottom: 40px;
      grid-column-gap: 40px;
    }

    // @media screen and (min-width: 1921px) {
    //   max-width: 1920px;
    // }

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  
  &__header {
    font-size: 36px;
    margin-bottom: .25em;
    font-family: HelveticaNeue,Arial,sans-serif;

    @media screen and (min-width: 1280px) {
      font-size: 40px;
    }

    @media screen and (min-width: 1440px) {
      font-size: 48px;
    }

    @media screen and (min-width: 1920px) {
      font-size: 56px;
    }

    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }

  &__title {
    font-size: 1em;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__text {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: .54em;

    @media screen and (min-width: 1440px) {
      font-size: 20px;
    }

    @media screen and (min-width: 1920px) {
      font-size: 24px;
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &__content {
    padding-right: 8%;

    @media screen and (min-width: 1440px) {
      padding-right: 0;
    }
  }

  &__controls.h-temp {
    display: none;
  }

  &__movie-wrap {
    position: relative;

    @media screen and (max-width: 767px) {
      margin-bottom: -8px;
    }

    .youtube {
      z-index: 0;
      width: 100%;
      padding-top: 56.17021276595745%;
      position: relative;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI1NjBweCIgaGVpZ2h0PSIzMTVweCIgdmlld0JveD0iMCAwIDU2MCAzMTUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PCEtLUdlbmVyYXRvcjogU2tldGNoIDUxLjIgKDU3NTE5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaC0tPjx0aXRsZT55b3V0dWJlLXBsYWNlaG9sZGVyPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBpZD0ieW91dHViZS1wbGFjZWhvbGRlciIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHJlY3QgaWQ9IlJlY3RhbmdsZS0zIiBmaWxsPSIjRTBFMEUwIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHg9IjAiIHk9IjAiIHdpZHRoPSI1NjAiIGhlaWdodD0iMzE1Ii8+PHBhdGggZD0iTTI3MCwxNzIgTDI3MCwxNDIgTDI5NywxNTcgTDI3MCwxNzIgWiBNMzE5Ljg1MDcwNSwxMjMuMTQ5NTQ4IEMzMTEuODk2NDQzLDEyMSAyODAsMTIxIDI4MCwxMjEgQzI4MCwxMjEgMjQ4LjEwMzU1NywxMjEgMjQwLjE0OTI5NSwxMjMuMTQ5NTQ4IEMyMzUuNzYwOTc3LDEyNC4zMzIzMjMgMjMyLjMwNDU2OCwxMjcuODE3MzU1IDIzMS4xMzE1NjgsMTMyLjI0MzAzMiBDMjI5LDE0MC4yNjQwNjUgMjI5LDE1NyAyMjksMTU3IEMyMjksMTU3IDIyOSwxNzMuNzM1MzU1IDIzMS4xMzE1NjgsMTgxLjc1Njk2OCBDMjMyLjMwNDU2OCwxODYuMTgyNjQ1IDIzNS43NjA5NzcsMTg5LjY2NzY3NyAyNDAuMTQ5Mjk1LDE5MC44NTEwMzIgQzI0OC4xMDM1NTcsMTkzIDI4MCwxOTMgMjgwLDE5MyBDMjgwLDE5MyAzMTEuODk2NDQzLDE5MyAzMTkuODUwNzA1LDE5MC44NTEwMzIgQzMyNC4yMzkwMjMsMTg5LjY2NzY3NyAzMjcuNjk1NDMyLDE4Ni4xODI2NDUgMzI4Ljg2ODQzMiwxODEuNzU2OTY4IEMzMzEsMTczLjczNTM1NSAzMzEsMTU3IDMzMSwxNTcgQzMzMSwxNTcgMzMxLDE0MC4yNjQwNjUgMzI4Ljg2ODQzMiwxMzIuMjQzMDMyIEMzMjcuNjk1NDMyLDEyNy44MTczNTUgMzI0LjIzOTAyMywxMjQuMzMyMzIzIDMxOS44NTA3MDUsMTIzLjE0OTU0OCBaIiBpZD0iRmlsbC0xIiBmaWxsPSIjQzJDMkMyIi8+PC9nPjwvc3ZnPg==);
      background-size: cover;
      background-position: 50% 50%;

      iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: auto;
      }
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------