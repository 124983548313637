/* .l-body
-------------------------------------------------------------*/
$r: ".l-body";

// base
//-----------------------------------------------------------
#{$r} {
  color: $body-color;
  font-size: 14px;
  line-height: 1.6;
  font-family: $ff-default;
  height: 100%;
  min-height: 100%;
  overflow: visible;

  // element
  //---------------------------------------------------------

  // modifiers
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
