/* .c-software
-----------------------------------------------------------*/
$r: ".c-software";

// block
//-----------------------------------------------------------
#{$r} {

  // element
  //---------------------------------------------------------
  &__container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1280px - 1) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 480px - 1) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1680px - 1) {
      padding-top: 44px;
      padding-bottom: 44px;
    }

    @media (max-width: 1440px - 1) {
      padding-top: 28px;
      padding-bottom: 28px;
      padding-left: 16px;
      padding-right: 16px;
    }

    @media (max-width: 1280px - 1) {
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 0;
      padding-right: 0;
    }

    @media (max-width: 992px - 1) {
      flex-direction: column-reverse;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-left: 64px;

    @media (max-width: 1440px - 1) {
      margin-left: 40px;
    }

    @media (max-width: 1280px - 1) {
      margin-left: 24px;
    }

    @media (max-width: 992px - 1) {
      margin-left: 0;
      width: auto;
    }

    img {
      width: 100%;
    }
  }

  &__body {
    flex: 1;
  }

  &__title {
    font-family: $ff-helvetica-neue;
    font-size: 44px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 0.4em;
    text-transform: capitalize;

    @media (max-width: 1920px - 1) {
      font-size: 40px;
    }

    @media (max-width: 1680px - 1) {
      font-size: 36px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 32px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 28px;
    }

    @media (max-width: 992px - 1) {
      font-size: 24px;
    }
  }

  &__content {
    font-size: 20px;

    @media (max-width: 1680px - 1) {
      font-size: 18px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 16px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 14px;
    }
  }

  &__list {
    display: block;
    font-size: 22px;
    line-height: 1.6;
    padding-left: 0;
    font-weight: 600;

    @media (max-width: 1680px - 1) {
      font-size: 20px;
    }

    @media (max-width: 1440px - 1) {
      font-size: 18px;
    }

    @media (max-width: 1280px - 1) {
      font-size: 16px;
    }

    @media (max-width: 992px - 1) {
      font-size: 14px;
    }

    li {
      display: flex;
      align-items: center;

      & + li {
        margin-top: 8px;
      }
    }

    &--check {
      li {
        background-image: url(#{$PATH_TO_IMAGES}/icon-check-yellow.svg);
        background-repeat: no-repeat;
        background-size: auto 1.75em;
        background-position: 0 50%;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        padding-left: 2.25em;
      }
    }
  }

  &__list {
    &--check {
      :root & {
        display: flex;
        flex-wrap: wrap;
        margin-left: -24px;
        margin-top: -8px + 16px;

        @media (max-width: 480px - 1) {
          margin-left: 0;
        }
      }

      li {
        :root & {
          flex: 1 0 40%;
          margin-left: 24px;
          margin-top: 8px;

          @media (max-width: 480px - 1) {
            flex-basis: 90%;
            margin-left: 0;
          }
        }
      }
    }
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
