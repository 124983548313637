/* screen - latest */

.latest {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 2621px;
  padding: 272px 0;
  width: 1920px;
}

.latest .latest-header {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  margin-left: 278px;
  min-width: 118px;
}

.latest .overlap-group3 {
  height: 50px;
  position: relative;
  width: 116px;
}

.latest .group-44 {
  align-items: flex-start;
  display: flex;
  height: 17px;
  left: 12px;
  position: absolute;
  top: 33px;
}

.latest .overlap-group1 {
  height: 18px;
  margin-top: -0.16px;
  position: relative;
  width: 104px;
}

.latest .vector-5-stroke {
  height: 14px;
  left: 6px;
  position: absolute;
  top: 3px;
  width: 97px;
}

.latest .vector-4-stroke {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 104px;
}

.latest .latest-1 {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.latest .nav-section {
  align-items: center;
  display: flex;
  height: 51px;
  margin-right: 187.0px;
  margin-top: 49px;
  min-width: 1177px;
}

.latest .nav-item {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 37px;
  width: 27px;
}

.latest .all {
  letter-spacing: 0;
  line-height: 23.4px;
  min-height: 23px;
  white-space: nowrap;
}

.latest .active {
  height: 3px;
  margin-top: 11px;
  width: 25px;
}

.latest .nav-item-1 {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  margin-left: 48px;
  margin-top: 0;
  min-width: 141px;
}

.latest .media-icon {
  align-self: center;
  height: 23px;
  width: 23px;
}

.latest .news-media {
  letter-spacing: 0;
  line-height: 23.4px;
  margin-left: 12px;
  min-height: 23px;
  white-space: nowrap;
}

.latest .nav-item-2 {
  align-items: flex-start;
  display: flex;
  margin-left: 48px;
  min-width: 75px;
}

.latest .press-icon {
  height: 23px;
  width: 18px;
}

.latest .press {
  letter-spacing: 0;
  line-height: 23.4px;
  margin-left: 12px;
  min-height: 23px;
  white-space: nowrap;
}

.latest .nav-item-3 {
  align-items: flex-start;
  display: flex;
  margin-left: 48px;
  min-width: 176px;
}

.latest .x-icon {
  height: 23px;
  width: 23px;
}

.latest .product-releases {
  letter-spacing: 0;
  line-height: 23.4px;
  margin-left: 13px;
  min-height: 23px;
  white-space: nowrap;
}

.latest .nav-item-4 {
  align-items: flex-start;
  display: flex;
  height: 24px;
  margin-bottom: 1.0px;
  margin-left: 48px;
  min-width: 71px;
}

.latest .blog {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: 23.4px;
  margin-left: 10px;
  min-height: 23px;
  min-width: 38px;
  white-space: nowrap;
}

.latest .search {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  margin-left: 138px;
  min-width: 359px;
}

.latest .overlap-group1-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 4px 4px 20px #0000001a;
  display: flex;
  height: 50px;
  min-width: 357px;
  padding: 13px 16px;
}

.latest .search-icon {
  align-self: center;
  height: 20px;
  width: 20px;
}

.latest .search-1 {
  letter-spacing: 0;
  line-height: 23.4px;
  margin-left: 12px;
  min-height: 23px;
  white-space: nowrap;
}

.latest .recent-section {
  align-items: flex-start;
  display: flex;
  height: 272px;
  margin-right: 186.0px;
  margin-top: 48px;
  min-width: 1178px;
}

.latest .featured-item {
  align-items: flex-start;
  display: flex;
  min-width: 826px;
}

.latest .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 210px;
  width: 322px;
}

.latest .text-61 {
  letter-spacing: 0;
  line-height: 28.1px;
  min-height: 56px;
  width: 322px;
}

.latest .text-62 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 15px;
  min-height: 108px;
  width: 322px;
}

.latest .address {
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 10px;
  min-height: 21px;
  white-space: nowrap;
}

.latest .img {
  height: 264px;
  margin-left: 28px;
  width: 470px;
}

.latest .featured-item-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 31px;
  min-height: 272px;
  width: 325px;
}

.latest .img-1 {
  height: 180px;
  width: 321px;
}

.latest .text-63 {
  letter-spacing: 0;
  line-height: 23.4px;
  margin-top: 15px;
  min-height: 46px;
  width: 322px;
}

.latest .sep {
  height: 2px;
  margin-right: 182.0px;
  margin-top: 39px;
  width: 1176px;
}

.latest .blog-section {
  align-items: flex-start;
  display: flex;
  height: 413px;
  margin-right: 168.0px;
  margin-top: 39px;
  min-width: 1196px;
}

.latest .flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 316px;
  width: 281px;
}

.latest .blog-item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 138px;
  width: 281px;
}

.latest .blog-icon {
  height: 23px;
  margin-left: 0;
  width: 23px;
}

.latest .text {
  letter-spacing: 0;
  line-height: 23.4px;
  margin-top: 15px;
  min-height: 69px;
  width: 279px;
}

.latest .blog-item-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  min-height: 138px;
  width: 281px;
}

.latest .blog-icon-2 {
  height: 23px;
  margin-left: -0.07px;
  width: 24px;
}

.latest .flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 26px;
  min-height: 293px;
  width: 281px;
}

.latest .blog-item-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 115px;
  width: 281px;
}

.latest .text-5 {
  letter-spacing: 0;
  line-height: 23.4px;
  margin-top: 15px;
  min-height: 46px;
  width: 279px;
}

.latest .blog-item-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 63px;
  min-height: 115px;
  width: 281px;
}

.latest .flex-col-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  min-height: 316px;
  width: 281px;
}

.latest .blog-icon-1 {
  height: 23px;
  margin-left: 1px;
  width: 23px;
}

.latest .flex-col-4 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  min-height: 413px;
  width: 281px;
}

.latest .blog-icon-3 {
  height: 23px;
  margin-left: 2px;
  width: 18px;
}

.latest .blog-icon-4 {
  height: 23px;
  margin-left: 2.0px;
  width: 23px;
}

.latest .pagination {
  align-items: flex-start;
  display: flex;
  height: 33px;
  margin-right: 16px;
  margin-top: 87px;
  min-width: 192px;
}

.latest .flex-col-5 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 33px;
  width: 23px;
}

.latest .number {
  letter-spacing: 0;
  line-height: 28.1px;
  margin-right: 3px;
  min-height: 28px;
  min-width: 15px;
  white-space: nowrap;
}

.latest .rectangle-61 {
  height: 3px;
  margin-top: 2px;
  width: 23px;
}

.latest .number-1 {
  letter-spacing: 0;
  line-height: 28.1px;
  margin-left: 23px;
  min-height: 28px;
  min-width: 15px;
  white-space: nowrap;
}

.latest .number-2 {
  letter-spacing: 0;
  line-height: 28.1px;
  margin-left: 26px;
  min-height: 28px;
  min-width: 15px;
  white-space: nowrap;
}

.latest .text-52 {
  letter-spacing: 0;
  line-height: 28.1px;
  margin-left: 26px;
  min-height: 28px;
  min-width: 18px;
  white-space: nowrap;
}

.latest .vector {
  align-self: center;
  height: 20px;
  margin-bottom: 5.0px;
  margin-left: 26px;
  width: 12px;
}

.latest .overlap-group {
  height: 955px;
  margin-top: 32px;
  position: relative;
  width: 1920px;
}

.latest .press-kit-section {
  align-items: flex-start;
  display: flex;
  height: 428px;
  left: 0;
  position: absolute;
  top: 527px;
}

.latest .press-hero {
  height: 428px;
  margin-top: 0;
  width: 581px;
}

.latest .flex-col-6 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-left: 310px;
  margin-top: 8.67px;
  min-height: 297px;
  width: 452px;
}

.latest .press-header {
  align-items: flex-end;
  display: flex;
  height: 50px;
  min-width: 167px;
}

.latest .overlap-group1-2 {
  height: 51px;
  margin-bottom: -0.45px;
  position: relative;
  width: 165px;
}

.latest .header-accent {
  height: 18px;
  left: 61px;
  position: absolute;
  top: 33px;
  width: 104px;
}

.latest .press-kit {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.latest .text-48 {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-l);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 31.5px;
  margin-top: 29px;
  min-height: 128px;
  width: 452px;
}

.latest .download-icon {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  margin-top: 63px;
  min-width: 123px;
}

.latest .vector-1 {
  height: 27px;
  margin-top: -0.17px;
  width: 24px;
}

.latest .download {
  align-self: center;
  letter-spacing: 0;
  line-height: 23.4px;
  margin-left: 14px;
  margin-top: 0.33px;
  min-height: 23px;
  min-width: 85px;
  white-space: nowrap;
}

.latest .press-section {
  align-items: center;
  display: flex;
  height: 563px;
  left: 278px;
  position: absolute;
  top: 0;
}

.latest .left-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 26.0px;
  min-height: 207px;
  width: 471px;
}

.latest .press-header-1 {
  align-items: flex-end;
  display: flex;
  height: 50px;
  min-width: 257px;
}

.latest .overlap-group1-3 {
  height: 51px;
  margin-bottom: -0.45px;
  position: relative;
  width: 255px;
}

.latest .header-accent-1 {
  height: 18px;
  left: 151px;
  position: absolute;
  top: 33px;
  width: 104px;
}

.latest .press-inquiries {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.latest .text-49 {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-l);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 31.5px;
  margin-top: 29px;
  min-height: 128px;
  width: 471px;
}

.latest .right-section {
  align-items: center;
  display: flex;
  margin-left: 142px;
  min-width: 1037px;
}

.latest .avatar {
  height: 152px;
  margin-top: 77.0px;
  width: 152px;
}

.latest .overlap-group3-1 {
  height: 563px;
  margin-left: 46px;
  position: relative;
  width: 831px;
}

.latest .overlap-group2 {
  height: 563px;
  left: 34px;
  position: absolute;
  top: 0;
  width: 797px;
}

.latest .bg-accent {
  height: 563px;
  left: 120px;
  position: absolute;
  top: 0;
  width: 677px;
}

.latest .text-50 {
  left: 0;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 337px;
  white-space: nowrap;
}

.latest .text-51 {
  left: 0;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 375px;
  white-space: nowrap;
}

.latest .lorem-ipsumv {
  left: 0;
  letter-spacing: 0;
  line-height: 32.4px;
  position: absolute;
  top: 244px;
  white-space: nowrap;
}

.latest .lorem-ipsumv-1 {
  left: 0;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 282px;
  white-space: nowrap;
}

.latest .flex-col-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 57px;
  position: absolute;
  top: 339px;
  width: 20px;
}

.latest .mobile {
  height: 22px;
  margin-left: 2.99px;
  width: 14px;
}

.latest .msg {
  height: 16px;
  margin-top: 19px;
  width: 20px;
}
