/* screen - products */

.products {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
}

.products .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  margin-top: 100px;
}
.box-title {
  font-family: Lato;
  color: #000;
  font-size: 20px;;
  font-style: normal;
  font-weight: 800;
}
.testimonial-bg{

}


.products .featured-section {
  align-items: flex-start;
  display: flex;
}

.products .hero-text {
  margin-bottom: 43.4px;
  min-height: 257px;
}

.products .overlap-group1 {
  height: 153px;
}

.products .text-accent {
  height: 21px;
  left: 283px;
  position: absolute;
  top: 132px;
  width: 122px;
}

.products-heading {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: 43px;
  font-weight: 800;
  letter-spacing: 0;
  line-height:55.6px;
}

.products .flex-row {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  margin-right: 6px;
  margin-top: 54px;
  min-width: 340px;
}

.products .overlap-group3 {
  align-items: flex-end;
  background-color: var(--firefly);
  border-radius: 10px;
  height: 50px;
  justify-content: flex-end;
  min-width: 120px;
  padding: 12px;
}

.products-btn {
  background: #0F2736;
  border-radius: 10px;
  color: white;
  display: block !important;
  padding: 10px 10px 10px 10px;
  width: 50%;
  text-align: center;
}
.products-btn-outline {
  border: 2px solid #000000;
  border-radius: 10px;
  color: #0F2736;
  font-size: 18px;
  display: block !important;
  padding: 7px 7px 7px 7px;
  text-align: center;
}

.products .request-free-demo {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  white-space: nowrap;
}

.products .hero-image {
}

.products .overlap-group-1 {
  margin-top: 85px;
  position: relative;
  width: 1508px;
}

.products .features-section {
  align-items: flex-end;
  display: flex;
}

.products .flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 349px;
}

.products .feature-title {
  align-items: flex-end;
  display: flex;
  height: 54px;
  min-width: 170px;
}

.products .overlap-group3-1 {
  height: 55px;
  margin-bottom: -0.49px;
  position: relative;
  width: 168px;
}

.products .text-accent-1 {
  height: 21px;
  left: 46px;
  position: absolute;
  top: 34px;
  width: 122px;
}

.products .features {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.products .feature-blockchain {
  align-items: flex-start;
  display: flex;
  height: 538px;
  min-width: 349px;
}

.products .overlap-group {
  height: 524px;
  margin-top: -16px;
  position: relative;
  width: 341px;
}

.products .blockchain-featured-bg {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 461px;
  left: 0;
  position: absolute;
  top: 63px;
  width: 341px;
}

.products .blockchain-featured {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-l);
  font-weight: 800;
  left: 25px;
  letter-spacing: 0;
  line-height: 31.5px;
  position: absolute;
  top: 115px;
}

.products .blockchain-icon {
  height: 120px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 120px;
}

.products .learn-more-btn {
  align-items: center;
  display: flex;
  height: 25px;
  left: 200px;
  position: absolute;
  top: 474px;
}

.products .learn-more {
  letter-spacing: 0;
  line-height: 25.2px;
  min-height: 25px;
  min-width: 95px;
  text-align: right;
  white-space: nowrap;
}

.products .more-icon {
  height: 14px;
  margin-bottom: 1.0px;
  margin-left: 9px;
  width: 14px;
}

.products .text-64 {
  left: 25px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 229px;
  width: 295px;
}

.products .text-65 {
  left: 25px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 402px;
  width: 295px;
}

.products .text-66 {
  left: 25px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 301px;
  width: 295px;
}

.products .feature-esig {
  align-items: flex-start;
  display: flex;
  height: 538px;
  min-width: 349px;
}

.products .esig-bg {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 461px;
  left: 0;
  position: absolute;
  top: 63px;
  width: 341px;
}

.products .learn-more-icon {
  align-items: center;
  display: flex;
  height: 25px;
  left: 200px;
  position: absolute;
  top: 461px;
}

.products .code {
  height: 120px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 120px;
}

.products .text-67 {
  left: 25px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 191px;
  width: 295px;
}

.products .text-68 {
  left: 25px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 263px;
  width: 295px;
}

.products .text-69 {
  left: 25px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 362px;
  width: 295px;
}

.products .text-70 {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-l);
  font-weight: 800;
  left: 25px;
  letter-spacing: 0;
  line-height: 29.4px;
  position: absolute;
  top: 115px;
}

.products .docuwalk-section {
  align-items: flex-start;
  display: flex;
}

.products .flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 268px;
  width: 673px;
}

.products .overlap-group4 {
  height: 52px;
  position: relative;
  width: 335px;
}

.products .text-accent-2 {
  align-items: flex-start;
  display: flex;
  height: 20px;
  justify-content: center;
  left: 213px;
  position: absolute;
  top: 32px;
}

.products .overlap-group1-1 {
  height: 20px;
  margin-top: -0.12px;
  position: relative;
  width: 122px;
}

.products .vector-5-stroke {
  height: 17px;
  left: 8px;
  position: absolute;
  top: 3px;
  width: 114px;
}

.products .vector-4-stroke {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 122px;
}

.products .what-is-docu-walk {
  letter-spacing: 0;
  white-space: nowrap;
}

.products .text-71 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 10px;
}

.products .overlap-group5 {
  align-self: flex-end;
  height: 447px;
  margin-left: 72px;
  position: relative;
  width: 758px;
}

.products .accent-blob {
  height: 392px;
  left: 360px;
  position: absolute;
  top: 55px;
  width: 399px;
}

.products .youtube {
  height: 305px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 515px;
}
