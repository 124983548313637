.privacy-security {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  width: 1920px;
}
.ngo-government ul {
  padding-left: 30px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  list-style-type: circle;
  line-height: 180%;
}
.solution-list-item {
  padding-bottom: 20px;
}
.privacy-security .overlap-group3 {
  height: 633px;
  margin-left: 212px;
  margin-top: 201px;
  position: relative;
  width: 1576px;
}

.privacy-security .privacy-section {
  display: flex;
  flex-direction: column;
  height: 633px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1576px;
}

.privacy-security .heading {
  align-items: center;
  display: flex;
  min-width: 1576px;
}

.privacy-security .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 28.26px;
  min-height: 159px;
  width: 314px;
}

.privacy-security .icon {
  background-color: var(--sail);
  border-radius: 10px;
  box-shadow: 4px 4px 20px #00000026;
  display: flex;
  height: 80px;
  width: 80px;
}

.privacy-security .lock-icon {
  height: 51px;
  margin-left: 20.9px;
  margin-top: 14.5px;
  width: 39.5px;
}

.privacy-security .overlap-group {
  height: 55px;
  margin-top: 24px;
  position: relative;
  width: 314px;
}

.privacy-security .text-acent {
  height: 20px;
  left: 192px;
  position: absolute;
  top: 35px;
  width: 122px;
}

.privacy-security .title {
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.privacy-security .bg-accent {
  display: flex;
  flex-direction: column;
  height: 231px;
  margin-left: 1148px;
  opacity: 0.2;
  width: 112px;
}

.privacy-security .ellipse-1 {
  background-color: var(--sunglow);
  border-radius: 55.15px;
  height: 110.30998992919922px;
  margin-left: 0;
  width: 110.30998992919922px;
}

.privacy-security .vector-1 {
  height: 110.5px;
  margin-left: -0.2px;
  margin-top: 10.3px;
  width: 112px;
}

.privacy-security .text-1 {
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 13.0px;
}

.ngo-government .list-item {
  display: flex;
  height: 27px;
  margin-left: 18px;
  margin-top: 32px;
  width: 411px;
}

.ngo-government .chart-icon {
  height: 22px;
  margin-left: -0.5px;
  margin-top: 1.6px;
  width: 23px;
}

.ngo-government .text-2 {
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 13.5px;
  white-space: nowrap;
}

.ngo-government .list-item-1 {
  display: flex;
  height: 27px;
  margin-left: 17px;
  margin-top: 20px;
  width: 470px;
}

.ngo-government .blockchain-icon {
  height: 22px;
  margin-left: -0.1px;
  margin-top: 2.6px;
  width: 25px;
}

.ngo-government .text-3 {
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 13.1px;
  white-space: nowrap;
}

.ngo-government .list-item-2 {
  display: flex;
  height: 27px;
  margin-left: 18px;
  margin-top: 20px;
  width: 411px;
}

.ngo-government .vr-icon {
  height: 15px;
  margin-left: -0.3px;
  margin-top: 4.8px;
  width: 24px;
}

.ngo-government .text-4 {
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 12.3px;
  white-space: nowrap;
}

.ngo-government .list-item-3 {
  display: flex;
  height: 27px;
  margin-left: 18px;
  margin-top: 20px;
  width: 468px;
}

.ngo-government .code-icon {
  height: 19px;
  margin-left: -0.2px;
  margin-top: 2.9px;
  width: 25px;
}

.ngo-government .text-5 {
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 11.2px;
  white-space: nowrap;
}

.privacy-security .product-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 589px;
  min-height: 375px;
  position: absolute;
  top: 244px;
  width: 915px;
}

.privacy-security .text-9 {
  letter-spacing: 0;
  line-height: 28.1px;
  min-height: 56px;
  width: 674px;
}

.privacy-security .flex-row {
  align-items: flex-end;
  display: flex;
  margin-top: 26px;
  min-width: 913px;
}

.privacy-security .overlap-group-1 {
  height: 277px;
  position: relative;
  width: 291px;
}

.privacy-security .rectangle-3 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 230px;
  left: 0;
  position: absolute;
  top: 47px;
  width: 291px;
}

.privacy-security .group-21106 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 4px 4px 20px #00000026;
  display: flex;
  height: 80px;
  left: 25px;
  min-width: 80px;
  padding: 17.7px 11.0px;
  position: absolute;
  top: 0;
}

.privacy-security .x1report {
  height: 44px;
  width: 58px;
}

.privacy-security .x1-r-eport {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 99px;
  white-space: nowrap;
}

.privacy-security .text-6 {
  left: 25px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 135px;
  width: 241px;
}

.privacy-security .group-21103 {
  display: flex;
  height: 25px;
  left: 146px;
  position: absolute;
  top: 231px;
  width: 118px;
}

.privacy-security .group-45 {
  display: flex;
  height: 25px;
  width: 120px;
}

.privacy-security .learn-more {
  letter-spacing: 0;
  line-height: 25.2px;
  text-align: right;
  white-space: nowrap;
}

.privacy-security .vector {
  height: 14px;
  margin-left: 9px;
  margin-top: 5px;
  width: 14px;
}

.privacy-security .overlap-group1 {
  height: 277px;
  margin-left: 20px;
  position: relative;
  width: 291px;
}

.privacy-security .text-7 {
  left: 26px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 135px;
  width: 241px;
}

.privacy-security .group-21104 {
  display: flex;
  height: 25px;
  left: 147px;
  position: absolute;
  top: 231px;
  width: 118px;
}

.privacy-security .docu-walk {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 99px;
  white-space: nowrap;
}

.privacy-security .group-21101 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 4px 4px 20px #00000026;
  display: flex;
  height: 80px;
  justify-content: flex-end;
  left: 25px;
  min-width: 80px;
  padding: 15.3px 13.9px;
  position: absolute;
  top: 0;
}

.privacy-security .docuwalk {
  height: 49px;
  width: 51px;
}

.privacy-security .overlap-group2 {
  height: 293px;
  margin-left: 20px;
  position: relative;
  width: 291px;
}

.privacy-security .rectangle-39 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 230px;
  left: 0;
  position: absolute;
  top: 63px;
  width: 291px;
}

.privacy-security .text-8 {
  left: 25px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 151px;
  width: 241px;
}

.privacy-security .group-21105 {
  display: flex;
  height: 25px;
  left: 146px;
  position: absolute;
  top: 247px;
  width: 118px;
}

.privacy-security .document-gps {
  left: 25px;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 115px;
  white-space: nowrap;
}

.privacy-security .document-gps-1 {
  height: 120px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 120px;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Lato:400,700,800");


.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}
