@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Lato:400,700,800");
:root {
  --black: #000000;
  --firefly: #0f2736;
  --sunglow: #fec135;
  --white: #ffffff;

  --font-size-l: 21px;
  --font-size-m: 18px;
  --font-size-20: 18px;
  --font-size-s: 16px;
  --font-size-xl: 21.6px;
  --font-size-xs: 14px;
  --font-size-xxl: 22.5px;
  --font-size-xxxl: 36px;

  --font-family-lato: "Lato", Helvetica;
  --font-family-lato-medium: "Lato-Medium", Helvetica;
  --font-family-lato-semibold: "Lato-SemiBold", Helvetica;
}
.lato-normal-firefly-18px {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.lato-bold-firefly-18px {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.lato-normal-firefly-22px {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: 24px;
  line-height: 35px !important;
  font-style: normal;
  font-weight: 400;
}
.lato-black-firefly-18px {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 900;
}

.lato-extra-bold-firefly-36px {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 800;
}

.lato-extra-bold-firefly-18px {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 800;
}

.lato-normal-white-18px {
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.lato-normal-sunglow-18px {
  color: var(--sunglow);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.lato-normal-firefly-14px {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.lato-bold-firefly-22-5px {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.lato-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.lato-bold-white-18px {
  color: var(--white);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.lato-bold-black-18px {
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.lato-normal-firefly-16px {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.lato-bold-firefly-27px {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
}

.lato-bold-firefly-21-6px {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.lato-bold-black-21-6px {
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.lato-medium-black-16px {
  color: var(--black);
  font-family: var(--font-family-lato-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.lato-normal-black-18px {
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}
.lato-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}
.form-field {
    margin-top: 20px;
    width: 100%;
    background: #fff;
    font-size: 18px;
    padding: 20px;
    box-shadow: 4px 4px 20px rgb(0 0 0 / 10%);
    border-radius: 5px
}
html, body {
  width: 100%;

}
.header {
  padding: 20px 0.0px;
}

.sz-logo {
  margin-top: -0.18px;
  width: 148px;
}

.footer {
}
.hero-background {
  background: url('../images/hero.png');
  background-size: cover;
  color: #fff;
  height: 700px;
  margin-top: -50px;
}
.hero-list {
  padding: 10px;
  background: rgba(255, 255, 255, .8);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-left: 25px;
}
.screen a {
  text-decoration: none;
  cursor: pointer;
}
.d-contents {
  display: contents !important;
}
.dropdown-item-product {
  display: block !important;
}
.wrapper {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.auto-animated div {
  --z-index: -1;
  opacity: 0;
  position: absolute;
}

.auto-animated .container-center-vertical,
.auto-animated .container-center-horizontal {
  opacity: 1;
}

.listeners-active,
.listeners-active * {
  pointer-events: auto;
}

.hidden,
.hidden * {
  pointer-events: none;
  visibility: hidden;
}

.listeners-active-click,
.listeners-active-click * {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}
.header nav {
  color: var(--firefly);
  font-family: var(--font-family-lato);
  display: inline-block;
  justify-content: flex-end;
}

.header nav li {
  display: inline-block;
  margin-right: 10%;
}

.header .nav-section {
  align-items: flex-start;
  display: flex;
  height: 89px;
  margin-left: 88.0px;
  min-width: 1728px;
  padding: 0 0.0px;
}
.header .facebook {
  margin-left: 10%;
}
.header .twitter {
  margin-left: 25px;
}
.header .hamburger {
  margin-left: 25px;
  margin-right: 15px;
  vertical-align: middle;
}
/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 64px !important;
  width: 64px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #FFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.header .instagram {
  margin-left: 25px;
}
.header .linkedin {
  margin-left: 25px;
}
.header .social-icon {
  display: inline-block;
  margin-right: 0px !important;
}
.nowrap {
  white-space: nowrap;
}
.header .search-icon {
  margin-left: 5% !important;
}
.header .nav-link-header {
  color: var(--main-text);
  font-family: var(--font-family-lato);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #0F2736;
  margin-bottom: 0;
  white-space: nowrap;
}
.header .nav-link-header:hover {
  color: #1D4B67;
}
.get-started-btn {
  background: #0F2736;
  border-radius: 10px;
  padding: 12px;
  white-space:nowrap;
}
.get-started-btn a{
  color: #ffffff !important;
}
.header .desktop .header {
  align-items: flex-start;
  display: flex;
  height: 89px;
  margin-left: 88.0px;
  min-width: 1728px;
  padding: 0 0.0px;
}
.header .sz-logo {
  height: 80px;
  margin-top: -0.18px;
  width: 118px;
}
.header .header-container {
  justify-content: flex-end;
  background: #F4F4F4;
  border-radius: 0px 0px 0px 60px;
  background-size: 100% 100%;
  padding: 25px 72px;
  flex: 1;
}
.header .header-container .active {
  border-bottom: 3px solid var(--sunglow);
  display: block;
  text-decoration: none;
  transition: all .2s ease;
}
.nav-link-side {
  display: block;
  text-decoration: none;
  padding-left: 30px;
  color: var(--main-text);
  font-family: var(--font-family-lato);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #0F2736;
  margin-bottom: 0;
}
.nav-item {
  padding-bottom: 20px;
}
.nav-item .active{
  border-left: 3px solid var(--sunglow);
  display: block;
  text-decoration: none;
  padding-left: 38px;
}
.nav-item .inactive{
  font-weight: bold;
  display: block;
  text-decoration: none;
  padding-left: 40px;
}
.submenu li {
  padding-top: 20px;
  padding-left: 40px;
}
.nav-link-side img{
  vertical-align: middle;
  padding-left: 10px;
}
.sidebar li .submenu{
	list-style: none;
  font-weight: 500;
	margin: 0;
	padding: 0;
	padding-left: 1rem;
	padding-right: 1rem;
}
.submenu li a{
  font-weight: 500;
}
.sidebar .nav-link-header {
    font-weight: 500;
    display: block;
    padding-bottom: 30px;
    color: var(--bs-dark);
}
.nav-link-header img {
  vertical-align: middle;
}
.sidebar .nav-link-header:hover {
    color: var(--bs-primary);
}
 .sidebar {
  margin-top: 100px;
  height: 100%;
  width: 90%;
  padding: 40px 20px 40px 0px;
  background: #F4F4F4;
  border-radius: 0px 60px 0px 0px;
}
.docuwalk-logo {
  height: 120px;
  padding-bottom: 40px;
  padding-left: 40px;
}
.add-padding {
  padding-bottom: 200%;
}

.dropbtn {
  font-family: var(--font-family-lato);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #0F2736;
  margin-bottom: 0;
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 1000;
  margin-top: 3px;
}

.dropdown-content {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px;
}
.menu-dropdown-img {
  width: 20px;
  float: left;
  text-align: center;
}
.menu-dropdown-img img {
    vertical-align: middle !important;
}
.menu-dropdown-text {
 margin-left: 35px;
 margin-top: 2px;
}
.flex-container {
    display: flex;
}

.flex-child {
    flex: 1;
}
.flex-child-2 {
    flex: 1;
}

.flex-child:first-child {
    margin-right: 20px;
}
.slide-out-content a {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #0F2736;
  text-decoration: none;
  display: block;
}
.slide-out-content .menu-header {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
}
.slide-out-content a:hover {
  transition: background-color 1s ease;
  background-color: #F3F8FE;
  border-radius: 50px;
}
.slide-out-content .menu-header {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
  color: #0F2736;
}
.dropdown-content a {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #0F2736;
  text-decoration: none;
  display: block;
}
.dropdown-content .menu-header {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
}
.dropdown-content a:hover {
  transition: background-color 1s ease;
  background-color: #F3F8FE;
  border-radius: 50px;
}
.slide-out :hover .dropdown-content {opacity: 1;visibility: visible;}

.dropdown:hover .dropbtn {color: #1F4F6E;}

.dropdown:hover .dropdown-content {opacity: 1;visibility: visible;}

.dropdown:hover .dropbtn {color: #1F4F6E;}

.testimonial-container {
  border-radius: 60px;
  position: relative;
}
.bg-orange {
  background: #FFF6ED;
}
.bg-green {
  background: #E0F1EE;
}
.bg-blue {
  background: #E4ECF5;
}
.quote-author {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}
.testimonial-logo {
  margin-top: 10px;
  display: block;
  width: 129px;
}
.testimonial-quote-icon {
  height: 40px;
  left: 39px;
  position: absolute;
  top: -20px;
  width: 41px;
}
.testimonial-text {
  padding: 40px;
}
.hs-input{
  margin-top: 20px;
  background: #FFFFFF;
  font-size: 18px;
  padding: 20px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.form-columns-1{
  padding-right: 0px !important;
  margin-right: 0px !important;
  max-width: none !important;
}
.form-columns-2{
  margin-right: 0px !important;
  max-width: none !important;
}
.hs-button{
  margin-top: 20px;
  width: 100%;
  color: white !important;
  background-color: var(--firefly);
  border-radius: 10px;
  height: 50px;
  min-width: 80px;
  padding: 12px 29px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
.hs-field-desc {
  margin-top: 20px;
  font-family: Lato;
  font-style: normal;
  font-size: 16px;
  color: #0F2736;
}
.hs-error-msgs {
  padding-left: 0px;
  padding-top: 10px;
}
.hs-main-font-element h2 {
  font-family: Lato;
  font-style: normal;
  font-size: 24px;
  color: #0F2736;
}
.fade-in-page {
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}
.home ul {
  list-style-type: circle !important;
}
.subscribe-wrapper {
  background: #FFF2FD;
  border-radius: 30px;
  padding: 80px 80px 80px 140px;
  position: relative;
}
.mail-section {
  align-items: center;
}
.mail-illustration {
  height: 352px;
  width: 243px;
  position: absolute;
  top: -50px;
  left: -220px;
}
.subscribe-form {
}
.subscribe-text {
  letter-spacing: 0;
  line-height: 50.4px;
}

.subscribe-subtext {
  letter-spacing: 0;
  line-height: 23.4px;
}
.overlap-group1 {
  height: 778px;
  position: relative;
  width: 1108px;
}
.mail-bg {
  height: 818px;
  left: 159px;
  position: absolute;
  top: 0;
  width: 949px;
}
.hs-richtext {
  padding-top: 20px;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

/*X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .hero-background {
    background: url('');
    height: 400px!important;
    margin-top: -50px !important;
  }
  .heading-3 {
    margin-top: 30px !important;
    padding-left: 0px !important;
  }
  .home .text-9 {
    padding-left: 0px !important;
  }
  .hero-list {
    margin-left: 0px !important;
  }
  .header .facebook {
    margin-left: 0% !important;
  }
}

/*Small devices (landscape phones, 576px and up)*/

@media (min-width: 575.98px) and (max-width: 991.98px) {
  .hero-background {
    background: url('');
    height: 500px!important;
    margin-top: -50px !important;
  }
  .heading-3 {
    margin-top: 30px !important;
    padding-left: 0px !important;
  }

  .home .text-9 {
    padding-left: 0px !important;
  }
  .hero-list {
    margin-left: 0px !important;
  }
  .header .facebook {
    margin-left: 0% !important;
  }
}

/*Medium devices (tablets, 768px and up)*/

@media (min-width: 767.98px) and (max-width: 991.98px) {
  .hero-background {
    background: url('');
    height: 500px !important;
    margin-top: -50px !important;
  }
  .hero-list {
    margin-left: 0px !important;
  }
  .header .facebook {
    margin-left: 0% !important;
  }
  .home .overlap-group17{
    width: 100% !important;
  }
  .home .text-10 {
    padding: 20px 10px 20px 10px!important;
    word-wrap: normal !important;
  }
  .home .heading-3 {
    padding-left: 0px !important;
  }
  .home .text-9{
    padding-left: 0px !important;
  }
  .header .header-container {
    padding: 20px;
  }
  .header .sz-logo{
    min-width: 60px !important;
  }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 991.98px) and (max-width: 1199.98px) {
  .hero-background {
    height: 600px!important;
    margin-top: -50px !important;
  }
  .header .facebook {
    margin-left: 0% !important;
  }
}

/*X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1399.98px) {
  .hero-background {
    height: 800px!important;
    margin-top: -100px !important;
  }
  .header nav li {
    margin-right: 5% !important;
  }
  .header .facebook {
    margin-left: 5% !important;
  }
}
